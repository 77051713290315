
<template>
  <div class="dashboard">

    <!-- details page -->
    <section class="pt-5">
      <div class="container shadow section-sm rounded">
        <div class="columns is-multiline">

          <!-- body -->
          <div class="column is-11-desktop is-12-tablet">
            <div class="tile is-ancestor">
              <div class="tile is-parent">
                <div class="card tile is-child">
                  <div class="card-content">
                    <div class="level is-mobile">
                      <div class="level-item">
                        <div class="is-widget-label">
                          <h3 class="subtitle is-spaced">
                            Clients
                          </h3>
                          <h1 class="title">
                            {{ clientCount }}
                          </h1>
                        </div>
                      </div>
                      <div class="level-item has-widget-icon">
                        <!-- <div class="is-widget-icon"><span class="icon has-text-primary is-large"><i
                                      class="mdi mdi-account-multiple mdi-48px"></i></span>
                                  </div> -->


                        <router-link :to="{ name: 'Clients' }" class="button is-success is-outlined">
                          <span class="icon is-small">
                            <i class="ti-user is-danger"></i>
                          </span>

                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tile is-parent">
                <div class="card tile is-child">
                  <div class="card-content">
                    <div class="level is-mobile">
                      <div class="level-item">
                        <div class="is-widget-label">
                          <h3 class="subtitle is-spaced">
                            Pending Check Ins
                          </h3>
                          <h1 class="title">
                            {{ pendingCheckInCount }}
                          </h1>
                        </div>
                      </div>
                      <div class="level-item has-widget-icon">
                        <!-- <div class="is-widget-icon"><span class="icon has-text-info is-large"><i
                                      class="mdi mdi-cart-outline mdi-48px"></i></span>
                                  </div> -->
                        <router-link :to="{ name: 'CheckInsVue' }" class="button is-info is-outlined">
                          <span class="icon is-small">
                            <i class="ti-import is-info"></i>
                          </span>

                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tile is-parent">
                <div class="card tile is-child">
                  <div class="card-content">
                    <div class="level is-mobile">
                      <div class="level-item">
                        <div class="is-widget-label">
                          <h3 class="subtitle is-spaced">
                            Pending requests
                          </h3>
                          <h1 class="title">
                            {{ pendingRequestsCount }}
                          </h1>
                        </div>
                      </div>
                      <div class="level-item has-widget-icon">
                        <!-- <div class="is-widget-icon"><span class="icon has-text-success is-large"><i
                                      class="ti-import is-info"></i></span>

                                  </div> -->

                        <router-link :to="{ name: 'Requests' }" class="button is-info is-outlined">
                          <span class="icon is-small">
                            <i class="ti-bell is-danger"></i>
                          </span>

                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2 class="mb-4 has-text-weight-medium ml-4">Camps</h2> <!-- main content -->
            <router-link to="/dashboard/add-camp" class="btn btn-sm btn-primary ml-4">Add New Camp</router-link>
            <!-- <hr> -->

            <template v-if="camps.length">
              <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Camp Name</th>
                    <th>Location</th>
                    <th>Contact Person</th>
                    <th>Contact Number(s)</th>
                    <th>Contact Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="(item, index) in camps" v-bind:key="item.id">

                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.location }}</td>
                    <td>{{ item.contact_person }}</td>
                    <td>
                      <ul>
                        <li v-for="(num, index) in item.contact_number" :key="index">
                          {{ num }}
                        </li>
                      </ul>
                    </td>
                    <td>{{ item.contact_email }}</td>
                    <td>

                      <div class="columns">
                        <!-- <div class="column">
                            <a @click="EditCampModal(item.id, item.name)" class="button is-info is-outlined">
                              <span class="icon is-small">
                                <i class="ti-eye is-info"></i>
                              </span>
                            </a>
                          </div> -->
                        <div class="column">

                          <router-link :to="`/dashboard/update-camp/${item.id}`" class="button is-info is-outlined">
                            <span class="icon is-small">
                              <i class="ti-pencil is-info"></i>
                            </span>
                          </router-link>
                        </div>
                        <div class="column">
                          <a @click="DeleteCampModal(item.id, item.name)" class="button is-danger is-outlined">
                            <span class="icon is-small">
                              <i class="ti-trash is-danger"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>

                </tbody>
              </table>

            </template>
            <template v-else>
              <hr class="ml-4">
              <p class="ml-4"> There are no camps available yet</p>
            </template>
            <!-- <div class="px-4"> -->

          </div>
        </div>
      </div>
    </section>
    <!-- /details page -->


    <!-- Delete Camp Modal -->
    <div v-if="DeleteCampOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete Camp</p>
          <button class="delete" aria-label="close" @click="DeleteCampModal()"></button>
        </header>
        <section class="modal-card-body">
          Are you sure you want to delete camp: <strong>{{ deleteCampName }}</strong>? <span
            class="has-text-danger has-text-weight-medium">This
            action can not be undone</span>.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="DeleteCamp()">Delete</button>
          <button class="button" @click="DeleteCampModal()">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { doc, collection, orderBy, serverTimestamp, getDocs, query, where, updateDoc, deleteDoc } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
  name: "DashboardComponent",
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    const OpenClose = ref(false)
    const DeleteCampOpenClose = ref(false)

    const editCampId = ref(null)
    const editCampName = ref(null)

    const deleteCampId = ref(null)
    const deleteCampName = ref(null)

    const add_camp = ref({
      camp_name: '',
      location: '',
      contact_person: '',
      contact_number: '267',
      contact_email: ''
    });
    const camps = ref([]);
    const phoneUsers = ref([]);
    const clientCount = ref(0);
    const pendingCheckInCount = ref(0);
    const pendingRequestsCount = ref(0);

    const store = useStore()
    const router = useRouter()


    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const Login = async () => {
      try {
        await store.dispatch('logIn', {
          email: email.value,
          password: password.value
        })
        getCamps()
        getPhoneUsers()
        getPendingCheckIns();
        getPendingRequests();
        router.push('/')
      }
      catch (err) {
        error.value = err.message
      }
    }



    const DeleteCampModal = (id, title) => {

      if (DeleteCampOpenClose.value == true) {
        deleteCampId.value = null;
        deleteCampName.value = null;

        DeleteCampOpenClose.value = !DeleteCampOpenClose.value;

      } else {
        deleteCampId.value = id;
        deleteCampName.value = title;
        DeleteCampOpenClose.value = !DeleteCampOpenClose.value;
      }

    }


    const UpdateCamp = async () => {
      if (add_camp.value.camp_name == '') {
        showToast('The camp name is missing', 'is-danger')
        return
      }
      if (add_camp.value.location == '') {
        showToast('The camp location is missing', 'is-danger')
        return
      }
      if (add_camp.value.contact_person == '') {
        showToast('The camp contact person is missing', 'is-danger')
        return
      }
      if (add_camp.value.contact_number == '267' || add_camp.value.contact_number == '') {
        showToast('The camp contact number is missing', 'is-danger')
        return
      }
      if (add_camp.value.contact_email == '') {
        showToast('The camp contact email is missing', 'is-danger')
        return
      }
      const updatedData = {
        camp_name: add_camp.value.camp_name,
        location: add_camp.value.location,
        contact_person: add_camp.value.contact_person,
        contact_number: add_camp.value.contact_number,
        contact_email: add_camp.value.contact_email,
        updated_by: auth.currentUser.uid,
        updated_at: serverTimestamp(),
      }


      try {
        const entryRef = doc(db, 'camps', editCampId.value);
        await updateDoc(entryRef, updatedData);
        // Do something else on success
        showToast('The camp has been updated successfully.', 'is-success')
        getCamps()
      } catch (error) {
        // Handle the error
        showToast('There has been an error while updating the camp. Please try again.', 'is-danger')
      }
      // await this.getCamps()
      editCampId.value = null;
      editCampName.value = null;
      add_camp.value.camp_name = '';
      add_camp.value.location = '';
      add_camp.value.contact_person = '';
      add_camp.value.contact_number = '267';
      add_camp.value.contact_email = '';

      OpenClose.value = !OpenClose.value;

    }

    const getCamps = async () => {
      const campsQuery = query(collection(db, "camps"), orderBy('created_at', 'desc'));
      const querySnapshot = await getDocs(campsQuery);
      // const querySnapshot = await getDocs(collection(db, "camps"));
      const campsData = [];
      querySnapshot.forEach((doc) => {
        // Split the string by comma to get an array of items
        const items = doc.data().contact_number.split(',').map(item => item.trim());

        // Iterate over the items and prepend each item with its index
        const formattedList = items.map((item, index) => `${index + 1}. ${item}`);

        // Join the formatted items into a single string with newlines
        // const constactList = formattedList.join('\n');
        campsData.push({
          id: doc.id,
          name: doc.data().camp_name,
          location: doc.data().location,
          contact_person: doc.data().contact_person,
          contact_number: formattedList,
          contact_email: doc.data().contact_email,
        })
      });
      camps.value = campsData;
    }

    const DeleteCamp = async () => {
      try {
        const entryRef = doc(db, 'camps', deleteCampId.value);
        await deleteDoc(entryRef);
        showToast('The camp has been deleted successfully.', 'is-success')
        getCamps()
      } catch (error) {
        showToast('There has been an error while deleting the camp. Please try again.', 'is-danger')
      }
      deleteCampId.value = null;
      deleteCampName.value = null;
      DeleteCampOpenClose.value = !DeleteCampOpenClose.value;
    }

    const getPhoneUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const phoneUsersData = [];
      querySnapshot.forEach((doc) => {
        phoneUsersData.push({
          id: doc.id,
          // Add other user data properties as needed
          name: doc.data().name,
          email: doc.data().email,
          phoneNumber: doc.data().phoneNumber,
          org: doc.data().org,
          uid: doc.data().uid
        });
      });
      phoneUsers.value = phoneUsersData;
      clientCount.value = phoneUsers.value.length;
    };

    const getPendingCheckIns = async () => {
      const q = query(
        collection(db, 'checkins'),
        where('status', '==', 'pending')
      );
      const querySnapshot = await getDocs(q);
      const checkInData = [];
      querySnapshot.forEach((doc) => {
        checkInData.push({
          id: doc.id,
        });
      });
      pendingCheckInCount.value = checkInData.length;

    }

    const getPendingRequests = async () => {
      const q = query(
        collection(db, 'servicerequests'),
        where('status', '==', 'pending')
      );
      const querySnapshot = await getDocs(q);
      const pendingRequestsData = [];
      querySnapshot.forEach((doc) => {
        pendingRequestsData.push({
          id: doc.id,
        });
      });
      pendingRequestsCount.value = pendingRequestsData.length;

    }


    const showToast = (message, type) => {
      toast({
        message: message,
        type: type,
        position: 'top-right',
        dismissible: true,
        pauseOnHover: true,
        duration: 3000, // show the message for 3 seconds
      })
    }

    const fetchData = () => {
      getCamps();
      getPhoneUsers();
      getPendingCheckIns();
      getPendingRequests();
    };

    onMounted(fetchData);
    return { Login, email, password, error, user, camps, add_camp, OpenClose, DeleteCampOpenClose, clientCount, pendingCheckInCount, pendingRequestsCount, editCampId, editCampName, deleteCampId, deleteCampName, DeleteCampModal, UpdateCamp, DeleteCamp, getCamps, showToast, getPhoneUsers }
  }
};
</script>
  