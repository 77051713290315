<template>
  <div class="requests">

    <!-- details page -->
    <section class="pt-5">
      <div class="container shadow section-sm rounded">
        <div class="columns is-multiline">

          <!-- body -->
          <div class="column is-11-desktop is-12-tablet">
            <!-- <div class="px-4"> -->
            <h2 class="mb-4 has-text-weight-medium">Service Requests</h2> <!-- main content -->
            <div class="columns">
              <div class="column is-2">
                <div class="select">
                  <select v-model="statusFilter" @change="requestsFilterChange()">
                    <option value="" disabled selected>Filter by Status</option>
                    <option value="all">All</option>
                    <option value="pending">Pending</option>
                    <option value="in_progress">In Progress</option>
                    <option value="done">Done</option>
                  </select>
                </div>
              </div>
              <div class="column is-4">
                <div class="select">
                  <select v-model="requestTypeFilter" @change="requestsFilterChange()">
                    <option value="" disabled selected>Filter by Request Type</option>
                    <option value="all">All</option>
                    <option value="catering">Catering</option>
                    <option value="housekeeping">Housekeeping</option>
                    <option value="laundry">Laundry</option>
                    <option value="maintenance">Maintenance</option>
                  </select>
                </div>
              </div>
            </div>
            <template v-if="requests.length">
              <!-- <hr> -->
              <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Request From</th>
                    <th>Room</th>
                    <th>Camp</th>
                    <th>Request Type</th>
                    <th>Request Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="(item, index) in requests" v-bind:key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.request_from }}</td>
                    <td>{{ item.room }}</td>
                    <td>{{ item.camp }}</td>
                    <template v-if="item.request_type == 'laundry'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large">
                          <i class="ti-bag is-danger"></i>
                        </span>
                        Laundry
                      </td>
                    </template>
                    <template v-if="item.request_type == 'maintenance'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large ">
                          <i class="ti-settings is-danger"> </i>
                        </span>
                        Maintenance
                      </td>
                    </template>
                    <template v-if="item.request_type == 'catering'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large">
                          <i class="ti-receipt is-danger"></i>
                        </span>
                        Catering
                      </td>
                    </template>
                    <template v-if="item.request_type == 'housekeeping'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large">
                          <i class="ti-home is-danger"></i>
                        </span>
                        Housekeeping
                      </td>
                    </template>
                    <td>{{ item.request_date }}</td>
                    <template v-if="item.status == 'done'">
                      <td><span style="color: green">Done</span></td>
                    </template>
                    <template v-if="item.status == 'in_progress'">
                      <td><span style="color: orange">In progress</span></td>
                    </template>
                    <template v-if="item.status == 'pending'">
                      <td><span style="color: red">Pending</span></td>
                    </template>
                    <td>
                      <div class="columns">
                        <div class="column">

                          <a @click="changeRequestStatusModal(item.id, item.request_from, item.request_type, item.room, item.camp, item.data)"
                            class="button is-info is-outlined">
                            <span class="icon is-small">
                              <i class="ti-arrow-circle-right is-info"></i>
                            </span>
                          </a>
                        </div>
                        <div class="column">

                          <a @click="deleteRequestModal(item.id, item.request_from, item.request_type)"
                            class="button is-danger is-outlined">
                            <span class="icon is-small">
                              <i class="ti-trash is-danger"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>

                </tbody>
              </table>

            </template>
            <template v-else>
              <hr class="ml-4">
              <p class="ml-4"> There are no requests available yet</p>
            </template>

          </div>
        </div>
      </div>
    </section>
    <!-- /details page -->

    <!-- Delete Request Modal -->
    <div v-if="DeleteRequestOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete Request</p>
          <button class="delete" aria-label="close" @click="deleteRequestModal()"></button>
        </header>
        <section class="modal-card-body">
          Are you sure you want to delete the {{ deleteRequestType }} request from <strong>{{ deleteRequestFrom
          }}</strong>? <span class="has-text-danger has-text-weight-medium">This
            action can not be undone</span>.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="deleteRequest()">Delete</button>
          <button class="button" @click="deleteRequestModal()">Cancel</button>
        </footer>
      </div>
    </div>

    <!-- Service Request Details Modal -->
    <div v-if="ChangeRequestStatusOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Service Request Details</p>
          <button class="delete" aria-label="close" @click="changeRequestStatusModal()"></button>
        </header>
        <section class="modal-card-body">
          <strong>{{ changeRequestStatusFrom }}</strong> of room: <strong>{{ changeRequestStatusRoom }}</strong> at
          <strong>{{ changeRequestStatusCamp }}</strong> requested
          for a <strong>{{ changeRequestStatusType }}</strong> service.
          <hr>
          <p><strong>Details:</strong></p>
          <p style="white-space: pre-wrap;">{{ changeRequestStatusDesc }}</p>
          <hr>
          <div class="select">
            <select v-model="newRequestStatus">
              <option value="" disabled selected>Select New Status</option>
              <option value="in_progress">In Progress</option>
              <option value="done">Done</option>
            </select>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button class="button is-info" @click="changeRequestStatus()">Submit</button>
          <button class="button" @click="changeRequestStatusModal()">Cancel</button>
        </footer>
      </div>
    </div>

  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { doc, collection, serverTimestamp, getDocs, query, orderBy, updateDoc, deleteDoc } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
  name: "CheckInComponent",
  setup() {
    const requests = ref([])
    const store = useStore()
    const DeleteRequestOpenClose = ref(false)
    const ChangeRequestStatusOpenClose = ref(false)

    const deleteRequestId = ref(null)
    const deleteRequestFrom = ref(null)
    const deleteRequestType = ref(null)

    const changeRequestStatustId = ref(null)
    const changeRequestStatusFrom = ref(null)
    const changeRequestStatusType = ref(null)
    const changeRequestStatusRoom = ref(null)
    const changeRequestStatusDesc = ref(null)
    const changeRequestStatusCamp = ref(null)

    const statusFilter = ref('');
    const requestTypeFilter = ref('');
    const campFilter = ref('');

    const newRequestStatus = ref('')


    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const getRequests = async () => {


      const requestsQuery = query(collection(db, "servicerequests"), orderBy('request_date', 'desc'));
      const querySnapshot = await getDocs(requestsQuery);
      // const querySnapshot = await getDocs(collection(db, "servicerequests"));
      const requestsData = [];
      var item_data = '';
      querySnapshot.forEach((doc) => {

        const requestDateTimestamp = doc.data().request_date;
        const requestDate = new Date(parseInt(requestDateTimestamp, 10));
        const requestDateFormattedDate = requestDate.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        });

        if (doc.data().request_type == 'housekeeping') {

          const parsedData = JSON.parse(doc.data().data);
          const category = parsedData.category;
          const description = parsedData.description;

          const formattedSentence = `Housekeeping Category: ${category}.\n\n${description}.`;

          item_data = formattedSentence;

        }

        if (doc.data().request_type == 'laundry') {


          const parsedData = JSON.parse(doc.data().data);

          const formattedItems = parsedData.map((item, index) => {
            return `${index + 1}. ${item.color} ${item.item} x ${item.qty}`;
          });

          const resultString = formattedItems.join('\n');

          item_data = resultString;

        }

        if (doc.data().request_type == 'maintenance') {

          item_data = doc.data().data;

        }

        if (doc.data().request_type == 'catering') {

          const parsedData = JSON.parse(doc.data().data);
          var week = parsedData.week;
          var day = parsedData.day.charAt(0).toUpperCase() + parsedData.day.slice(1);
          var time = parsedData.time.charAt(0).toUpperCase() + parsedData.time.slice(1);
          var item = parsedData.item;

          if (time == 'Live_cooking') {
            time = 'Live Cooking';
          }

          const formattedSentence = `Week: ${week}.\n\nDay: ${day}.\n\nOrder: ${item} for ${time}.`;

          item_data = formattedSentence;

        }

        requestsData.push({
          id: doc.id,
          // Add other user data properties as needed
          camp: doc.data().camp,
          request_date: requestDateFormattedDate,
          request_from: doc.data().request_from,
          room: doc.data().room,
          request_type: doc.data().request_type,
          status: doc.data().status,
          data: item_data
        });
      });
      requests.value = requestsData;
    };

    const deleteRequestModal = (id, from, type) => {

      if (DeleteRequestOpenClose.value == true) {

        deleteRequestId.value = null;
        deleteRequestFrom.value = null;
        deleteRequestType.value = null;

        DeleteRequestOpenClose.value = !DeleteRequestOpenClose.value;

      } else {

        deleteRequestId.value = id;
        deleteRequestFrom.value = from;
        deleteRequestType.value = type;
        DeleteRequestOpenClose.value = !DeleteRequestOpenClose.value;
      }
    }

    const changeRequestStatusModal = (id, from, type, room, camp, data) => {

      if (ChangeRequestStatusOpenClose.value == true) {

        changeRequestStatustId.value = null
        changeRequestStatusFrom.value = null
        changeRequestStatusType.value = null
        changeRequestStatusRoom.value = null
        changeRequestStatusDesc.value = null
        changeRequestStatusCamp.value = null

        newRequestStatus.value = ''

        ChangeRequestStatusOpenClose.value = !ChangeRequestStatusOpenClose.value;

      } else {

        changeRequestStatustId.value = id;
        changeRequestStatusFrom.value = from;
        changeRequestStatusType.value = type;
        changeRequestStatusRoom.value = room;
        changeRequestStatusDesc.value = data;
        changeRequestStatusCamp.value = camp;
        ChangeRequestStatusOpenClose.value = !ChangeRequestStatusOpenClose.value;
      }
    }


    const changeRequestStatus = async () => {
      if (newRequestStatus.value == '') {
        showToast('The new request status is missing', 'is-danger')
        return
      }
      const newRequestStatusData = {
        updated_at: serverTimestamp(),
        updated_by: auth.currentUser.uid,
        status: newRequestStatus.value,
      }

      try {
        const entryRef = doc(db, 'servicerequests', changeRequestStatustId.value);
        await updateDoc(entryRef, newRequestStatusData);
        // Do something else on success
        showToast('The new request status has been set successfully.', 'is-success')
        getRequests()
      } catch (error) {
        // Handle the error
        showToast('There has been an error while setting the new request status. Please try again.', 'is-danger')
      }

      changeRequestStatustId.value = null
      changeRequestStatusFrom.value = null
      changeRequestStatusType.value = null
      changeRequestStatusRoom.value = null
      changeRequestStatusDesc.value = null
      changeRequestStatusCamp.value = null

      newRequestStatus.value = ''

      ChangeRequestStatusOpenClose.value = !ChangeRequestStatusOpenClose.value;
    }

    const deleteRequest = async () => {
      try {
        const entryRef = doc(db, 'servicerequests', deleteRequestId.value);
        await deleteDoc(entryRef);
        showToast('The client request has been deleted successfully.', 'is-success')
        getRequests()
      } catch (error) {
        showToast('There has been an error while deleting the client request. Please try again.', 'is-danger')
      }

      deleteRequestId.value = null;
      deleteRequestFrom.value = null;
      deleteRequestType.value = null;

      DeleteRequestOpenClose.value = !DeleteRequestOpenClose.value;
    }

    const requestsFilterChange = async () => {
      const status = statusFilter.value;
      const requestType = requestTypeFilter.value;
      // const camp = campFilter.value;

      const filteredRequestData = [];
      if (status == 'all' && requestType == 'all') {
        getRequests()
      } else {
        if ((status == 'all' || status == '') && requestType != 'all') {
          await getRequests()
          requests.value.forEach((req) => {
            if (requestType == req.request_type) {
              filteredRequestData.push(
                req
              )
            }
          })

        }
        if (status != 'all' && (requestType == 'all' || requestType == '')) {
          await getRequests()
          requests.value.forEach((req) => {
            if (status == req.status) {
              filteredRequestData.push(
                req
              )
            }
          })

        }
        if (status != 'all' && requestType != 'all') {
          await getRequests()
          requests.value.forEach((req) => {
            if (status == req.status && requestType == req.request_type) {
              filteredRequestData.push(
                req
              )
            }
          })

        }
        requests.value = filteredRequestData
      }

    }

    const showToast = (message, type) => {
      toast({
        message: message,
        type: type,
        position: 'top-right',
        dismissible: true,
        pauseOnHover: true,
        duration: 3000, // show the message for 3 seconds
      })
    }

    const fetchData = () => {
      getRequests();
    };

    onMounted(fetchData);
    return { user, requests, DeleteRequestOpenClose, newRequestStatus, ChangeRequestStatusOpenClose, changeRequestStatustId, changeRequestStatusFrom, changeRequestStatusType, changeRequestStatusRoom, changeRequestStatusDesc, changeRequestStatusCamp, deleteRequestId, deleteRequestFrom, deleteRequestType, requestTypeFilter, statusFilter, campFilter, requestsFilterChange, deleteRequestModal, changeRequestStatusModal, changeRequestStatus, deleteRequest, showToast }
  }
};
</script>