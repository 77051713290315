<template>
    <div class="questionnaires">

        <!-- details page -->
        <section class="pt-5">
            <div class="container shadow section-sm rounded">
                <div class="columns is-multiline">

                    <!-- body -->
                    <div class="column is-11-desktop is-12-tablet">
                        <!-- <div class="px-4"> -->
                        <h2 class="mb-4 has-text-weight-medium">Responses</h2> <!-- main content -->
                        <template v-if="checkIns.length">
                            <table class="table is-striped is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Room</th>
                                        <th>Camp</th>
                                        <th>Client</th>
                                        <th>Submission date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="item in checkIns" v-bind:key="item.id">
                                        <td>{{ item.room }}</td>
                                        <td>{{ item.camp }}</td>
                                        <td>{{ item.client }}</td>
                                        <td>{{ item.check_in_date }}</td>
                                        <td>

                                            <a class="button is-info is-outlined">
                                                <span class="icon is-small">
                                                    <i class="ti-eye is-info"></i>
                                                </span>
                                            </a>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>

                        </template>
                        <template v-else>
                            <hr class="ml-4">
                            <p class="ml-4"> There are no requests available yet</p>
                        </template>

                    </div>
                </div>
            </div>
        </section>
        <!-- /details page -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            checkIns: [
                {
                    id: 1,
                    room: 'Room 20',
                    camp: 'Motheo village camp',
                    client: 'Gaone Seokana',
                    check_in_date: '2023-01-05 14:34:14',
                    status: 'still_in'
                },
                {
                    id: 1,
                    room: 'Room 21',
                    camp: 'Motheo village camp',
                    client: 'Modibedi Tibi',
                    check_in_date: '2023-01-05 14:34:24',
                    status: 'still_in'
                },
                {
                    id: 1,
                    room: 'Room 11',
                    camp: 'Ghanzi Village Camp',
                    client: 'Mpuang Lesego',
                    check_in_date: '2022-07-05 14:34:14',
                    status: 'checked_out'
                },
                {
                    id: 1,
                    room: 'Room 2',
                    camp: 'Motheo village camp 1',
                    client: 'Omaatla Sefo',
                    check_in_date: '2022-07-05 14:34:14',
                    status: 'checked_out'
                },
                {
                    id: 1,
                    room: 'Room 33',
                    camp: 'Ghanzi Village Camp',
                    client: 'Lefika Ngakantsi',
                    check_in_date: '2019-07-05 14:34:14',
                    status: 'checked_out'
                },
            ]
        }
    },
    mounted() {

    }
}
</script>