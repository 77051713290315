<template>
    <div class="feedback">

        <!-- details page -->
        <section class="pt-5">
            <div class="container shadow section-sm rounded">
                <div class="columns is-multiline">

                    <!-- body -->
                    <div class="column is-11-desktop is-12-tablet">
                        <!-- <div class="px-4"> -->
                        <h2 class="mb-4 has-text-weight-medium">Client Feedback</h2> <!-- main content -->
                        <div class="columns">
                            <div class="column is-2">
                                <div class="select">
                                    <select v-model="statusFilter" @change="statusFilterChange()">
                                        <option value="" disabled selected>Filter by Status</option>
                                        <option value="all">All</option>
                                        <option value="unseen">Unseen</option>
                                        <option value="seen">Seen</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <template v-if="feedback.length">
                            <!-- <hr> -->
                            <table class="table is-striped is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Camp</th>
                                        <th>Client</th>
                                        <th>Date</th>
                                        <th>Item</th>
                                        <th>Rating</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="(item, index) in feedback" v-bind:key="item.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.camp }}</td>
                                        <td>{{ item.client }}</td>
                                        <td>{{ item.created_at }}</td>
                                        <template v-if="item.item == 'Catering'">

                                            <!-- <td style="display: flex; align-items: center;">

                                                <span class="icon is-large">
                                                    <i class="ti-receipt is-danger"></i>
                                                </span>
                                                Catering
                                            </td> -->
                                            <td class="is-flex is-align-items-center">
                                                <span class="icon is-large">
                                                    <i class="ti-receipt is-danger"></i>
                                                </span>
                                                Catering
                                            </td>
                                        </template>
                                        <template v-if="item.item == 'Housekeeping'">

                                            <td class="is-flex is-align-items-center">
                                                <span class="icon is-large ">
                                                    <i class="ti-home is-danger"> </i>
                                                </span>
                                                Housekeeping
                                            </td>
                                        </template>
                                        <template v-if="item.item == 'Laundry'">

                                            <td class="is-flex is-align-items-center">
                                                <span class="icon is-large">
                                                    <i class="ti-bag is-danger"></i>
                                                </span>
                                                Laundry
                                            </td>
                                        </template>
                                        <template v-if="item.item == 'Maintenance'">

                                            <td class="is-flex is-align-items-center">
                                                <span class="icon is-large">
                                                    <i class="ti-settings is-danger"></i>
                                                </span>
                                                Maintenance
                                            </td>
                                        </template>
                                        <td>{{ item.rating }}</td>
                                        <template v-if="item.status == 'unseen'">
                                            <td><span style="color: orange">Unseen</span></td>
                                        </template>
                                        <template v-if="item.status == 'seen'">
                                            <td><span style="color: green">Seen</span></td>
                                        </template>
                                        <td>
                                            <div class="columns">
                                                <div v-if="item.status == 'unseen'">

                                                    <div class="column">
                                                        <a @click="changeFeedbackStatusModal(item.id, item.client, item.item, item.camp, item.description)"
                                                            class="button is-info is-outlined">
                                                            <span class="icon is-small">
                                                                <i class="ti-arrow-circle-right is-info"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="column">
                                                        <a class="button is-greyy is-outlined">
                                                            <span class="icon is-small">
                                                                <i class="ti-arrow-circle-right is-grey"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="column">

                                                    <a @click="deleteFeedbackModal(item.id, item.client, item.item)"
                                                        class="button is-danger is-outlined">
                                                        <span class="icon is-small">
                                                            <i class="ti-trash is-danger"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>

                        </template>
                        <template v-else>
                            <hr class="ml-4">
                            <p class="ml-4"> There are no client feedback available yet</p>
                        </template>

                    </div>
                </div>
            </div>
        </section>
        <!-- /details page -->

        <!-- Delete Feedback Modal -->
        <div v-if="DeleteFeedbackOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete Feedback</p>
                    <button class="delete" aria-label="close" @click="deleteFeedbackModal()"></button>
                </header>
                <section class="modal-card-body">
                    Are you sure you want to delete the <strong>{{ deleteFeedbackItem }}</strong> feedback from <strong>{{
                        deleteFeedbackClient
                    }}</strong>? <span class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>.
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="deleteFeedback()">Delete</button>
                    <button class="button" @click="deleteFeedbackModal()">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Change Feedback Status Modal -->
        <div v-if="ChangeFeedbackStatusOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Feedback Status</p>
                    <button class="delete" aria-label="close" @click="changeFeedbackStatusModal()"></button>
                </header>
                <section class="modal-card-body">
                    <strong>{{ changeFeedbackStatusClient }}</strong>
                    at
                    <strong>{{ changeFeedbackStatusCamp }}</strong> has logged a
                    <strong>{{ changeFeedbackStatusItem }}</strong> feedback.
                    <hr>
                    <p><strong>Description:</strong></p>
                    <p>{{ changeFeedbackStatusDescription }}</p>
                    <hr>
                    <div class="select">
                        <select v-model="newFeedbackStatus">
                            <option value="" disabled selected>Select New Status</option>
                            <option value="seen">Seen</option>
                        </select>
                    </div>

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-info" @click="changeFeedbackStatus()">Submit</button>
                    <button class="button" @click="changeFeedbackStatusModal()">Cancel</button>
                </footer>
            </div>
        </div>

    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { doc, collection, serverTimestamp, getDocs, updateDoc, query, orderBy, deleteDoc } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
    name: "CheckInComponent",
    setup() {
        const feedback = ref([])
        const store = useStore()
        const DeleteFeedbackOpenClose = ref(false)
        const ChangeFeedbackStatusOpenClose = ref(false)

        const deleteFeedbackId = ref(null)
        const deleteFeedbackClient = ref(null)
        const deleteFeedbackItem = ref(null)

        const changeFeedbackStatustId = ref(null)
        const changeFeedbackStatusClient = ref(null)
        const changeFeedbackStatusItem = ref(null)
        const changeFeedbackStatusDescription = ref(null)
        const changeFeedbackStatusCamp = ref(null)

        const statusFilter = ref('');
        const campFilter = ref('');

        const newFeedbackStatus = ref('')


        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
        });

        const user = computed(() => {
            return store.getters.user;
        });

        const getFeedback = async () => {

            const feedbackQuery = query(collection(db, "feedback"), orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(feedbackQuery);
            // const querySnapshot = await getDocs(collection(db, "feedback"));
            const feedbackData = [];
            querySnapshot.forEach((doc) => {

                const createdAtTimestamp = doc.data().createdAt;
                const createdAt = new Date(parseInt(createdAtTimestamp, 10));
                var camp = 'Offsite'
                const createdAtFormattedDate = createdAt.toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                });

                if (doc.data().camp != '') {
                    camp = doc.data().camp
                }

                feedbackData.push({
                    id: doc.id,
                    // Add other user data properties as needed
                    camp: camp,
                    client: doc.data().client,
                    created_at: createdAtFormattedDate,
                    description: doc.data().description,
                    item: doc.data().item,
                    rating: doc.data().rating,
                    status: doc.data().status,
                });
            });
            feedback.value = feedbackData;
        };

        const deleteFeedbackModal = (id, client, type) => {

            if (DeleteFeedbackOpenClose.value == true) {

                deleteFeedbackId.value = null;
                deleteFeedbackClient.value = null;
                deleteFeedbackItem.value = null;

                DeleteFeedbackOpenClose.value = !DeleteFeedbackOpenClose.value;

            } else {

                deleteFeedbackId.value = id;
                deleteFeedbackClient.value = client;
                deleteFeedbackItem.value = type;
                DeleteFeedbackOpenClose.value = !DeleteFeedbackOpenClose.value;
            }
        }

        const changeFeedbackStatusModal = (id, client, item, camp, description) => {

            if (ChangeFeedbackStatusOpenClose.value == true) {

                changeFeedbackStatustId.value = null
                changeFeedbackStatusClient.value = null
                changeFeedbackStatusItem.value = null
                changeFeedbackStatusDescription.value = null
                changeFeedbackStatusCamp.value = null

                newFeedbackStatus.value = ''

                ChangeFeedbackStatusOpenClose.value = !ChangeFeedbackStatusOpenClose.value;

            } else {

                changeFeedbackStatustId.value = id;
                changeFeedbackStatusClient.value = client;
                changeFeedbackStatusItem.value = item;
                changeFeedbackStatusDescription.value = description;
                changeFeedbackStatusCamp.value = camp;
                ChangeFeedbackStatusOpenClose.value = !ChangeFeedbackStatusOpenClose.value;
            }
        }


        const changeFeedbackStatus = async () => {
            if (newFeedbackStatus.value == '') {
                showToast('The new feedback status is missing', 'is-danger')
                return
            }
            const newFeedbackStatusData = {
                updated_at: serverTimestamp(),
                updated_by: auth.currentUser.uid,
                status: newFeedbackStatus.value,
            }

            try {
                const entryRef = doc(db, 'feedback', changeFeedbackStatustId.value);
                await updateDoc(entryRef, newFeedbackStatusData);
                // Do something else on success
                showToast('The new feedback status has been set successfully.', 'is-success')
                getFeedback()
            } catch (error) {
                // Handle the error
                showToast('There has been an error while setting the new feedback status. Please try again.', 'is-danger')
            }

            changeFeedbackStatustId.value = null
            changeFeedbackStatusClient.value = null
            changeFeedbackStatusItem.value = null
            changeFeedbackStatusDescription.value = null
            changeFeedbackStatusCamp.value = null

            newFeedbackStatus.value = ''

            ChangeFeedbackStatusOpenClose.value = !ChangeFeedbackStatusOpenClose.value;
        }

        const deleteFeedback = async () => {
            try {
                const entryRef = doc(db, 'feedback', deleteFeedbackId.value);
                await deleteDoc(entryRef);
                showToast('The client feedback has been deleted successfully.', 'is-success')
                getFeedback()
            } catch (error) {
                showToast('There has been an error while deleting the client feedback. Please try again.', 'is-danger')
            }

            deleteFeedbackId.value = null;
            deleteFeedbackClient.value = null;
            deleteFeedbackItem.value = null;

            DeleteFeedbackOpenClose.value = !DeleteFeedbackOpenClose.value;
        }

        const statusFilterChange = async () => {
            const status = statusFilter.value;
            // const camp = campFilter.value;

            const filteredFeedbackData = [];
            if (status == 'all') {
                getFeedback()
            } else {
                await getFeedback()
                feedback.value.forEach((req) => {
                    if (status == req.status) {
                        filteredFeedbackData.push(
                            req
                        )
                    }
                })
                feedback.value = filteredFeedbackData
            }

        }

        const showToast = (message, type) => {
            toast({
                message: message,
                type: type,
                position: 'top-right',
                dismissible: true,
                pauseOnHover: true,
                duration: 3000, // show the message for 3 seconds
            })
        }

        const fetchData = () => {
            getFeedback();
        };

        onMounted(fetchData);
        return { user, feedback, DeleteFeedbackOpenClose, newFeedbackStatus, ChangeFeedbackStatusOpenClose, changeFeedbackStatustId, changeFeedbackStatusClient, changeFeedbackStatusItem, changeFeedbackStatusDescription, changeFeedbackStatusCamp, deleteFeedbackId, deleteFeedbackClient, deleteFeedbackItem, statusFilter, campFilter, statusFilterChange, deleteFeedbackModal, changeFeedbackStatusModal, changeFeedbackStatus, deleteFeedback, showToast }
    }
};
</script>