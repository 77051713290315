<template>
  <div>
    <main class="py-4">
      <NavVue />
      <router-view />

      <FooterVue />
    </main>;
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";

import { auth } from './firebaseConfig'

import NavVue from '@/components/NavVue.vue'
import FooterVue from '@/components/FooterVue.vue'


export default {
  name: "App",

  setup() {

    const store = useStore()
    const router = useRouter()

    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      await store.dispatch('logOut')
      router.push('/sign-in')
    }

    return { user, signOut }
  },
  components: {
    NavVue,
    FooterVue,
  },
  metaInfo: {
    script: [
      { src: './assets/plugins/jQuery/jquery.min.js', async: true, defer: true },
      { src: './assets/plugins/masonry/masonry.min.js', async: true, defer: true },
      { src: './assets/plugins/clipboard/clipboard.min.js', async: true, defer: true },
      { src: './assets/plugins/match-height/jquery.matchHeight-min.js', async: true, defer: true },


      { src: './assets/js/script.js', async: true, defer: true },
      { src: './assets/js/modal.js', async: true, defer: true },


    ],
  },
};

</script>

<style lang="scss">
@import './assets/plugins/bulma/bulma.min.css';
@import './assets/plugins/themify-icons/themify-icons.css';
@import './assets/css/style.css';
</style>