<template>
    <div class="client">

        <!-- details page -->
        <section class="pt-5">
        <div class="container shadow section-sm rounded">
            <div class="columns is-multiline">
                <!-- sidebar -->
                <div class="column is-3-desktop is-12-tablet">
                    <ul class="sidenav">
                  <div class="has-text-centered">
                    <figure class="image container is-128x128 ">
                      <img class="is-rounded" src="https://bulma.io/images/placeholders/128x128.png">
                    </figure>
                    <h6>Aobakwe Seokana</h6>
                    <hr>

                  </div>
                    <li title="Basic Startup" class="sidelist parent active">
                        <h1>Explore Profile</h1>
                        <ul>
                            <li>
                                <a @click="switch_tab('basic_info')">
                                    Basic Info
                                </a>
                            </li>
                            <li>
                                <a @click="switch_tab('learning')">
                                    Learning
                                </a>
                            </li>
                            <li>
                                <a @click="switch_tab('wishlist')">
                                    Wishlist
                                </a>
                            </li>
                            <li>
                                <a @click="switch_tab('security')">
                                    Security
                                </a>
                            </li>
                            <li>
                                <a @click="logout()">
                                    Sign Out
                                </a>
                            </li>
                        </ul>
                    </li>
                    </ul>
                </div>

                <!-- body -->
                <div class="column is-8-desktop is-12-tablet">
                    <!-- <div class="px-4"> -->
                      <!-- Basic Info -->
                      <template v-if="basic_info">
                        <div class="column is-10">
                        <h2 class="mb-4 has-text-weight-medium">Basic Info</h2> <!-- main content -->
                            
                        </div>

                      </template>

                      <!-- Learning -->
                      <template v-if="learning">
                        <div class="column is-10">
                        <h2 class="mb-4 has-text-weight-medium">Learning</h2> <!-- main content -->
                            <div class="columns is-multiline">
                                <!-- <template v-if="$store.state.user.isAuthenticated"> -->

                                  
                            </div>
                        </div>
                      </template>

                      <!-- Wishlist -->
                      <template v-if="wishlist">
                        <div class="column is-10">
                        <h2 class="mb-4 has-text-weight-medium">Wishlist</h2> 
                        </div>

                      </template>

                      <!-- Security -->
                      <template v-if="security">
                        <div class="column is-10">
                        <h2 class="mb-4 has-text-weight-medium">Security</h2> <!-- main content -->
                            
                        </div>

                      </template>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        </section>
        <!-- /details page -->


    </div>
  </template>

  <script>
    export default{
        data() {
          return {
            courses: [],
            basic_info: true,
            learning: false,
            wishlist: false,
            security: false
          }
        },
        components: {
            
        },
        mounted() {
            
        },
        methods:{
            switch_tab(tab){
              if(tab == 'basic_info'){
                this.basic_info = true
                this.learning = false
                this.wishlist = false
                this.security = false

              }
              if(tab == 'learning'){
                this.basic_info = false
                this.learning = true
                this.wishlist = false
                this.security = false
                
              }
              if(tab == 'wishlist'){
                this.basic_info = false
                this.learning = false
                this.wishlist = true
                this.security = false
                
              }
              if(tab == 'security'){
                this.basic_info = false
                this.learning = false
                this.wishlist = false
                this.security = true
                
              }
            }
        }
    }
  </script>
  