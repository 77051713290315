<template>
  <div class="clients">

    <!-- details page -->
    <section class="pt-5">
      <div class="container shadow section-sm rounded">
        <div class="columns is-multiline">

          <!-- body -->
          <div class="column is-11-desktop is-12-tablet">
            <!-- <div class="px-4"> -->
            <h2 class="mb-4 has-text-weight-medium">Registered Users</h2> <!-- main content -->
            <div class="columns">
              <div class="column is-2">
                <div class="select">
                  <select v-model="statusFilter" @change="statusFilterChange()">
                    <option value="" disabled selected>Filter by Status</option>
                    <option value="all">All</option>
                    <option value="pending">Pending</option>
                    <option value="active">Active</option>
                    <option value="deactivated">Deactivated</option>
                  </select>
                </div>
              </div>
            </div>
            <template v-if="phoneUsers.length">
              <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Names</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Organization</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="(item, index) in phoneUsers" v-bind:key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.phoneNumber }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.org }}</td>
                    <template v-if="item.status == 'active'">
                      <td><span style="color: green">Active</span></td>
                    </template>
                    <template v-if="item.status == 'pending'">
                      <td><span style="color: orange">Pending</span></td>
                    </template>
                    <template v-if="item.status == 'deactivated'">
                      <td><span style="color: red">Deactivated</span></td>
                    </template>
                    <td>
                      <!-- <router-link :to="{ name: 'Client' }" class="button is-info is-outlined">
                        <span class="icon is-small">
                          <i class="ti-more is-danger"></i>
                        </span>

                      </router-link> -->
                      <template v-if="item.status == 'pending'">
                        <div class="columns">
                          <div class="column">

                            <a class="button ml-3 is-success is-outlined"
                              @click.prevent="userActivateModal(item.id, item.name)">
                              <span class="icon is-small">
                                <i class="ti-check-box is-success"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-close is-grey"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </template>

                      <template v-if="item.status == 'active'">
                        <div class="columns">
                          <div class="column">
                            <a class="button ml-3 is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-check-box is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-danger is-outlined"
                              @click.prevent="userDeactivateModal(item.id, item.name)">
                              <span class="icon is-small">
                                <i class="ti-close is-danger"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </template>

                      <template v-if="item.status == 'deactivated'">
                        <div class="columns">
                          <div class="column">
                            <a class="button ml-3 is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-check-box is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-close is-grey"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </template>

                    </td>

                  </tr>

                </tbody>
              </table>

            </template>
            <template v-else>
              <hr class="ml-4">
              <p class="ml-4"> There are no users available yet</p>
            </template>

          </div>
        </div>
      </div>
    </section>
    <!-- /details page -->

    <!-- Activate User Modal -->
    <div v-if="UserActivateOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Activate User</p>
          <button class="delete" aria-label="close" @click="userActivateModal()"></button>
        </header>
        <section class="modal-card-body">
          You are about to activate <strong>{{ userName }}</strong>'s account. Are you sure you want to continue?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="userActivate()">Activate</button>
          <button class="button" @click="userActivateModal()">Cancel</button>
        </footer>
      </div>
    </div>

    <!-- Deactivate User Modal -->
    <div v-if="UserDeactivateOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Deactivate User</p>
          <button class="delete" aria-label="close" @click="userDeactivateModal()"></button>
        </header>
        <section class="modal-card-body">
          You are about to deactivate <strong>{{ userName }}</strong>'s account. Are you sure you want to continue? <span
            class="has-text-danger has-text-weight-medium">This action can not be undone.</span>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="userDeactivate()">Deactivate</button>
          <button class="button" @click="userDeactivateModal()">Cancel</button>
        </footer>
      </div>
    </div>

  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { doc, collection, serverTimestamp, query, orderBy, updateDoc, getDocs } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
  name: "ClientsComponent",
  setup() {
    const phoneUsers = ref([]);

    const statusFilter = ref('');
    const userId = ref(null);
    const userName = ref(null);
    const UserActivateOpenClose = ref(false)
    const UserDeactivateOpenClose = ref(false)

    const store = useStore()

    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const getPhoneUsers = async () => {


      const usersQuery = query(collection(db, "users"), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(usersQuery);
      // const querySnapshot = await getDocs(collection(db, "users"));
      const phoneUsersData = [];
      querySnapshot.forEach((doc) => {
        phoneUsersData.push({
          id: doc.id,
          // Add other user data properties as needed
          name: doc.data().name,
          email: doc.data().email,
          phoneNumber: doc.data().phoneNumber,
          status: doc.data().status,
          org: doc.data().org,
          uid: doc.data().uid
        });
      });
      phoneUsers.value = phoneUsersData;
    };

    const userActivateModal = (id, name) => {

      if (UserActivateOpenClose.value == true) {

        userId.value = null
        userName.value = null

        UserActivateOpenClose.value = !UserActivateOpenClose.value;

      } else {

        userId.value = id;
        userName.value = name;
        UserActivateOpenClose.value = !UserActivateOpenClose.value;
      }
    }

    const userDeactivateModal = (id, name) => {

      if (UserDeactivateOpenClose.value == true) {

        userId.value = null
        userName.value = null

        UserDeactivateOpenClose.value = !UserDeactivateOpenClose.value;

      } else {

        userId.value = id;
        userName.value = name;
        UserDeactivateOpenClose.value = !UserDeactivateOpenClose.value;
      }
    }

    const userActivate = async () => {
      const userActivateData = {
        activated_at: serverTimestamp(),
        activated_by: auth.currentUser.uid,
        status: "active",
      }

      try {
        const entryRef = doc(db, 'users', userId.value);
        await updateDoc(entryRef, userActivateData);
        // Do something else on success
        showToast('The user account has been activated successfully.', 'is-success')
        getPhoneUsers()
      } catch (error) {
        showToast('There has been an error while activating the account. Please try again.', 'is-danger')
      }

      userId.value = null
      userName.value = null

      UserActivateOpenClose.value = !UserActivateOpenClose.value;

    }

    const userDeactivate = async () => {
      const userActivateData = {
        activated_at: serverTimestamp(),
        activated_by: auth.currentUser.uid,
        status: "deactivated",
      }

      try {
        const entryRef = doc(db, 'users', userId.value);
        await updateDoc(entryRef, userActivateData);
        // Do something else on success
        showToast('The user account has been deactivated successfully.', 'is-success')
        getPhoneUsers()
      } catch (error) {
        showToast('There has been an error while deactivating the account. Please try again.', 'is-danger')
      }

      userId.value = null
      userName.value = null

      UserDeactivateOpenClose.value = !UserDeactivateOpenClose.value;

    }


    const showToast = (message, type) => {
      toast({
        message: message,
        type: type,
        position: 'top-right',
        dismissible: true,
        pauseOnHover: true,
        duration: 3000, // show the message for 3 seconds
      })
    }

    const statusFilterChange = async () => {
      const status = statusFilter.value;
      // const camp = campFilter.value;

      const filteredRequestData = [];
      if (status == 'all') {
        getPhoneUsers()
      } else {
        await getPhoneUsers()
        phoneUsers.value.forEach((req) => {
          if (status == req.status) {
            filteredRequestData.push(
              req
            )
          }
        })
        phoneUsers.value = filteredRequestData
      }

    }

    const fetchData = () => {
      getPhoneUsers();
    };

    onMounted(fetchData);
    return { user, userId, userName, phoneUsers, UserActivateOpenClose, statusFilter, UserDeactivateOpenClose, userActivateModal, userDeactivateModal, userActivate, userDeactivate, showToast, statusFilterChange }
  }
};
</script>
    