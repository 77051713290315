// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDd3GuY0Jr73Q4eunFMujRCWcPpjjUR7bk",
  authDomain: "mighty-infinite-services.firebaseapp.com",
  databaseURL: "https://mighty-infinite-services.firebaseapp.com",
  projectId: "mighty-infinite-services",
  storageBucket: "mighty-infinite-services.appspot.com",
  messagingSenderId: "137670458099",
  measurementId: "G-14TCZY8X59",
  appId: "1:137670458099:web:eca59050373b6d713cd586"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth()
const db = getFirestore(app);


export { app, auth, db }