<template>
  <div class="checkins">

    <!-- details page -->
    <section class="pt-5">
      <div class="container shadow section-sm rounded">
        <div class="columns is-multiline">

          <!-- body -->
          <div class="column is-11-desktop is-12-tablet">
            <!-- <div class="px-4"> -->
            <h2 class="mb-4 has-text-weight-medium">Check Ins</h2> <!-- main content -->
            <div class="columns">
              <div class="column is-2">
                <div class="select">
                  <select v-model="statusFilter" @change="statusFilterChange()">
                    <option value="" disabled selected>Filter by Status</option>
                    <option value="all">All</option>
                    <option value="pending">Pending</option>
                    <option value="still_in">Checked In</option>
                    <option value="checked_out">Checked Out</option>
                  </select>
                </div>
              </div>
              <!-- <div class="column is-3">
                  <div class="select">
                    <select v-model="campFilter" @change="campFilterChange()">
                      <option value="" disabled selected>Filter by Camps</option>
                      <option value="in_progress">In Progress</option>
                      <option value="done">Done</option>
                    </select>
                  </div>
                </div> -->
            </div>
            <template v-if="checkIns.length">
              <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Type</th>
                    <th>Room</th>
                    <th>Camp</th>
                    <th>Client</th>
                    <th>Expected date</th>
                    <th>Check in date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="(item, index) in checkIns" v-bind:key="item.id">

                    <td>{{ index + 1 }}</td>

                    <td>
                      <div v-if="item.type == 'first'">First</div>
                      <div v-if="item.type == 'return'">Return</div>
                    </td>
                    <td>{{ item.room }}</td>
                    <td>{{ item.camp }}</td>
                    <td>{{ item.client }}</td>
                    <td>{{ item.expected_date }}</td>
                    <td>{{ item.check_in_date }}</td>
                    <template v-if="item.status == 'pending'">

                      <td>
                        <span class="has-text-danger">
                          Pending
                        </span>
                      </td>
                      <td>
                        <div class="columns">
                          <div class="column">
                            <div v-if="item.type == 'first'">
                              <a @click="checkInModal(item.id, item.client)" class="button is-success is-outlined">
                                <span class="icon is-small">
                                  <i class="ti-import is-success"></i>
                                </span>
                              </a>
                            </div>
                            <div v-if="item.type == 'return'">
                              <a @click="checkInModal(item.id, item.client, item.room)"
                                class="button is-success is-outlined">
                                <span class="icon is-small">
                                  <i class="ti-import is-success"></i>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-export is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a @click="checkInCancelModal(item.id, item.client)" class="button is-danger is-outlined">
                              <span class="icon is-small">
                                <i class="ti-close is-danger"></i>
                              </span>
                            </a>
                          </div>
                        </div>

                      </td>
                    </template>
                    <template v-if="item.status == 'cancelled'">

                      <td>
                        <span class="has-text-grey">
                          Cancelled
                        </span>
                      </td>
                      <td>
                        <div class="columns">
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-import is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-export is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-close is-grey"></i>
                              </span>
                            </a>
                          </div>
                        </div>

                      </td>
                    </template>
                    <template v-if="item.status == 'still_in'">

                      <td>
                        <span class="has-text-success">
                          Checked in
                        </span>
                      </td>
                      <td>

                        <div class="columns">
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-import is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a @click="checkOutModal(item.id, item.client)" class="button is-info is-outlined">
                              <span class="icon is-small">
                                <i class="ti-export is-info"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-close is-grey"></i>
                              </span>
                            </a>
                          </div>
                        </div>


                      </td>
                    </template>
                    <template v-if="item.status == 'checked_out'">

                      <td>
                        <span class="has-text-grey">
                          Checked Out
                        </span><br>
                        <span class="has-text-grey">
                          ({{ item.check_out_date }})
                        </span>
                      </td>
                      <td>

                        <div class="columns">
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-import is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-export is-grey"></i>
                              </span>
                            </a>
                          </div>
                          <div class="column">
                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-close is-grey"></i>
                              </span>
                            </a>
                          </div>
                        </div>


                      </td>
                    </template>

                  </tr>

                </tbody>
              </table>

            </template>
            <template v-else>
              <hr class="ml-4">
              <p class="ml-4"> There are no requests available yet</p>
            </template>

          </div>
        </div>
      </div>
    </section>
    <!-- /details page -->

    <!-- CheckIn Modal -->
    <div v-if="checkInOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Check In</p>
          <button class="delete" aria-label="close" @click="checkInModal()"></button>
        </header>
        <section class="modal-card-body">
          You are about to check <strong>{{ checkInOutClient }}</strong> in.
          <hr>
          <div class="field">
            <label>Room</label>
            <div class="control">
              <input placeholder="e.g Room 24" type="text" class="input mb-2" v-model="checkInRoom">
            </div>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="checkIn()">Confirm</button>
          <button class="button" @click="checkInModal()">Cancel</button>
        </footer>
      </div>
    </div>

    <!-- CheckIn Cancel Modal -->
    <div v-if="checkInCancelOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Cancel Check In</p>
          <button class="delete" aria-label="close" @click="checkInCancelModal()"></button>
        </header>
        <section class="modal-card-body">
          You are about to cancel <strong>{{ checkInOutClient }}</strong>'s check in request.

        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="checkInCancel()">Confirm</button>
          <button class="button" @click="checkInCancelModal()">Cancel</button>
        </footer>
      </div>
    </div>

    <!-- CheckOut Modal -->
    <div v-if="checkOutOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Check Out</p>
          <button class="delete" aria-label="close" @click="checkOutModal()"></button>
        </header>
        <section class="modal-card-body">
          You are about to check <strong>{{ checkInOutClient }}</strong> out.

        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="checkOut()">Confirm</button>
          <button class="button" @click="checkOutModal()">Cancel</button>
        </footer>
      </div>
    </div>

  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { doc, collection, serverTimestamp, getDocs, query, orderBy, updateDoc } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
  name: "CheckInComponent",
  setup() {
    const checkIns = ref([]);
    // const filteredCheckIns = ref([]);
    const checkInOpenClose = ref(false);
    const checkOutOpenClose = ref(false);
    const checkInCancelOpenClose = ref(false);

    const checkInOutID = ref(null);
    const checkInOutClient = ref(null);
    const checkInRoom = ref('');

    const statusFilter = ref('');
    const campFilter = ref('');
    const camps = ref([]);

    const store = useStore()

    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const getCheckIns = async () => {
      const checkInsQuery = query(collection(db, "checkins"), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(checkInsQuery);
      // const querySnapshot = await getDocs(collection(db, "checkins"));
      const checkinsData = [];
      querySnapshot.forEach((doc) => {

        let checkInDateFormattedDate = '';
        let checkOutDateFormattedDate = '';
        let expectedDateFormattedDate = '';

        if (doc.data().check_in_date == "-") {
          checkInDateFormattedDate = doc.data().check_in_date
        } else {
          const checkInDateTimestamp = doc.data().check_in_date;
          const checkInDate = checkInDateTimestamp.toDate();
          checkInDateFormattedDate = checkInDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          });
        }

        if (doc.data().check_out_date == "-") {
          checkOutDateFormattedDate = doc.data().check_out_date
        } else {
          const checkOutDateTimestamp = doc.data().check_out_date;
          const checkOutDate = checkOutDateTimestamp.toDate();
          checkOutDateFormattedDate = checkOutDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          });
        }

        if (doc.data().expected_date == "-") {
          expectedDateFormattedDate = doc.data().expected_date
        } else {
          const expectedDateTimestamp = doc.data().expected_date;
          const expectedDate = new Date(parseInt(expectedDateTimestamp) * 1000);

          expectedDateFormattedDate = expectedDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          });
        }

        checkinsData.push({
          id: doc.id,
          // Add other user data properties as needed
          camp: doc.data().camp_name,
          client: doc.data().client,
          type: doc.data().type,
          expected_date: expectedDateFormattedDate,
          check_in_date: checkInDateFormattedDate,
          check_out_date: checkOutDateFormattedDate,
          room: doc.data().room,
          status: doc.data().status,
          uid: doc.data().uid
        });
      });
      checkIns.value = checkinsData;
    };

    const checkInModal = (id, client, room) => {

      if (checkInOpenClose.value == true) {

        checkInOutID.value = null;
        checkInOutClient.value = null;
        checkInRoom.value = '';

        checkInOpenClose.value = !checkInOpenClose.value;

      } else {

        checkInOutID.value = id;
        checkInOutClient.value = client;
        checkInRoom.value = room;


        checkInOpenClose.value = !checkInOpenClose.value;
      }

    }

    const checkInCancelModal = (id, client) => {

      if (checkInCancelOpenClose.value == true) {

        checkInOutID.value = null;
        checkInOutClient.value = null;
        checkInRoom.value = '';

        checkInCancelOpenClose.value = !checkInCancelOpenClose.value;

      } else {

        checkInOutID.value = id;
        checkInOutClient.value = client;


        checkInCancelOpenClose.value = !checkInCancelOpenClose.value;
      }

    }

    const checkOutModal = (id, client) => {

      if (checkOutOpenClose.value == true) {

        checkInOutID.value = null;
        checkInOutClient.value = null;

        checkOutOpenClose.value = !checkOutOpenClose.value;

      } else {

        checkInOutID.value = id;
        checkInOutClient.value = client;

        checkOutOpenClose.value = !checkOutOpenClose.value;
      }

    }

    const checkIn = async () => {

      if (checkInRoom.value === '' || checkInRoom.value === undefined) {
        showToast('The check in room is missing', 'is-danger')
        return
      }
      const checkInData = {
        room: checkInRoom.value,
        check_in_date: serverTimestamp(),
        checked_in_by: auth.currentUser.uid,
        status: "still_in",
      }

      try {
        const entryRef = doc(db, 'checkins', checkInOutID.value);
        await updateDoc(entryRef, checkInData);
        // Do something else on success
        showToast('The client has been checked in successfully.', 'is-success')
        getCheckIns()
      } catch (error) {
        // Handle the error
        showToast('There has been an error while checking in the client. Please try again.', 'is-danger')
      }

      checkInOutID.value = null;
      checkInOutClient.value = null;
      checkInRoom.value = '';

      checkInOpenClose.value = !checkInOpenClose.value;
    }

    const checkInCancel = async () => {

      const checkInCancelData = {
        check_in_date: serverTimestamp(),
        cancelled_by: auth.currentUser.uid,
        status: "cancelled",
      }

      try {
        const entryRef = doc(db, 'checkins', checkInOutID.value);
        await updateDoc(entryRef, checkInCancelData);
        // Do something else on success
        showToast('The client check in has been cancelled successfully.', 'is-success')
        getCheckIns()
      } catch (error) {
        // Handle the error
        showToast('There has been an error while cancelling check in. Please try again.', 'is-danger')
      }

      checkInOutID.value = null;
      checkInOutClient.value = null;

      checkInCancelOpenClose.value = !checkInCancelOpenClose.value;
    }

    const checkOut = async () => {

      const checkOutData = {
        check_out_date: serverTimestamp(),
        checked_out_by: auth.currentUser.uid,
        status: "checked_out",
      }

      try {
        const entryRef = doc(db, 'checkins', checkInOutID.value);
        await updateDoc(entryRef, checkOutData);
        // Do something else on success
        showToast('The client has been checked out successfully.', 'is-success')
        getCheckIns()
      } catch (error) {
        // Handle the error
        showToast('There has been an error while checking out the client. Please try again.', 'is-danger')
      }

      checkInOutID.value = null;
      checkInOutClient.value = null;

      checkOutOpenClose.value = !checkOutOpenClose.value;
    }

    const statusFilterChange = async () => {
      const status = statusFilter.value;
      // const camp = campFilter.value;

      const filteredCheckinsData = [];
      if (status == 'all') {
        getCheckIns()
      } else {
        await getCheckIns()
        checkIns.value.forEach((checkin) => {
          if (status == checkin.status) {
            filteredCheckinsData.push(
              checkin
            )
          }
        })
        checkIns.value = filteredCheckinsData
      }

    }

    const campFilterChange = () => {

      // if (filteredCheckIns.value.length) {

      // }
    }

    const getCamps = async () => {
      const querySnapshot = await getDocs(collection(db, "camps"));
      const campsData = [];
      querySnapshot.forEach((doc) => {
        campsData.push({
          id: doc.id,
          name: doc.data().camp_name,
          location: doc.data().location,
          contact_person: doc.data().contact_person,
          contact_number: doc.data().contact_number,
          contact_email: doc.data().contact_email,
        })
      });
      camps.value = campsData;
    }

    const showToast = (message, type) => {
      toast({
        message: message,
        type: type,
        position: 'top-right',
        dismissible: true,
        pauseOnHover: true,
        duration: 3000, // show the message for 3 seconds
      })
    }

    const fetchData = () => {
      getCamps();
      getCheckIns();
    };

    onMounted(fetchData);
    return { user, checkIns, checkInOutID, checkInOutClient, checkInRoom, checkInOpenClose, checkInCancelOpenClose, checkOutOpenClose, statusFilter, campFilter, checkInCancelModal, checkInCancel, statusFilterChange, campFilterChange, checkInModal, checkIn, checkOut, checkOutModal, showToast }
  }
};
</script>
    