<template>
  <div class="incidents">

    <!-- details page -->
    <section class="pt-5">
      <div class="container shadow section-sm rounded">
        <div class="columns is-multiline">

          <!-- body -->
          <div class="column is-11-desktop is-12-tablet">
            <!-- <div class="px-4"> -->
            <h2 class="mb-4 has-text-weight-medium">Incident Reports</h2> <!-- main content -->
            <div class="columns">
              <div class="column is-2">
                <div class="select">
                  <select v-model="statusFilter" @change="statusFilterChange()">
                    <option value="" disabled selected>Filter by Status</option>
                    <option value="all">All</option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              </div>
              <!-- <div class="column is-3">
                  <div class="select">
                    <select v-model="campFilter" @change="campFilterChange()">
                      <option value="" disabled selected>Filter by Camps</option>
                      <option value="in_progress">In Progress</option>
                      <option value="done">Done</option>
                    </select>
                  </div>
                </div> -->
            </div>
            <template v-if="incidents.length">
              <!-- <hr> -->
              <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Report From</th>
                    <th>Camp</th>
                    <th>Incident</th>
                    <th>Report Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="(item, index) in incidents" v-bind:key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.client }}</td>
                    <td>{{ item.camp }}</td>
                    <template v-if="item.incident == 'Fire'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large ">
                          <i class="ti-info-alt is-danger"> </i>
                        </span>
                        Fire
                      </td>
                    </template>
                    <template v-if="item.incident == 'Flood'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large ">
                          <i class="ti-info-alt is-danger"> </i>
                        </span>
                        Flood
                      </td>
                    </template>
                    <template v-if="item.incident == 'Theft'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large">
                          <i class="ti-info-alt is-danger"></i>
                        </span>
                        Theft
                      </td>
                    </template>
                    <template v-if="item.incident == 'Wild Animals'">

                      <td class="is-flex is-align-items-center">
                        <span class="icon is-large">
                          <i class="ti-info-alt is-danger"></i>
                        </span>
                        Wild Animal
                      </td>
                    </template>
                    <td>{{ item.created_at_date }}</td>
                    <template v-if="item.status == 'open'">
                      <td><span style="color: red">Open</span></td>
                    </template>
                    <template v-if="item.status == 'closed'">
                      <td><span style="color: green">Closed</span></td>
                    </template>
                    <td>
                      <div class="columns">
                        <div class="column">

                          <a @click="reportDetailsStatusModal(item.id, item.client, item.incident, item.camp, item.description)"
                            class="button is-info is-outlined">
                            <span class="icon is-small">
                              <i class="ti-eye is-info"></i>
                            </span>
                          </a>
                        </div>
                        <div class="column">

                          <template v-if="item.status == 'open'">

                            <a @click="changeReportStatusModal(item.id, item.client, item.incident, item.camp)"
                              class="button is-success is-outlined">
                              <span class="icon is-small">
                                <i class="ti-arrow-circle-right is-success"></i>
                              </span>
                            </a>

                          </template>
                          <template v-else>

                            <a class="button is-grey is-outlined">
                              <span class="icon is-small">
                                <i class="ti-arrow-circle-right is-grey"></i>
                              </span>
                            </a>

                          </template>
                        </div>
                        <div class="column">

                          <a @click="deleteReportModal(item.id, item.client, item.incident)"
                            class="button is-danger is-outlined">
                            <span class="icon is-small">
                              <i class="ti-trash is-danger"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>

                </tbody>
              </table>

            </template>
            <template v-else>
              <hr class="ml-4">
              <p class="ml-4"> There are no incidents available yet</p>
            </template>

          </div>
        </div>
      </div>
    </section>
    <!-- /details page -->

    <!-- Report Details Modal -->
    <div v-if="ReportDetailsOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Report Details</p>
          <button class="delete" aria-label="close" @click="reportDetailsStatusModal()"></button>
        </header>
        <section class="modal-card-body">
          <strong>{{ reportDetailsFrom }}</strong> has reported <strong>{{ reportDetailsIncident }}</strong> at <strong>{{
            reportDetailsCamp
          }}</strong>.
          <hr>
          <h6 class="mb-2 has-text-weight-medium">Description:</h6>
          {{ reportDetailsDescription }}
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="reportDetailsStatusModal()">Cancel</button>
        </footer>
      </div>
    </div>

    <!-- Delete Report Modal -->
    <div v-if="DeleteReportOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete Report</p>
          <button class="delete" aria-label="close" @click="deleteReportModal()"></button>
        </header>
        <section class="modal-card-body">
          Are you sure you want to delete the <strong>{{ deleteReportType }}</strong> report from <strong>{{
            deleteReportFrom
          }}</strong>? <span class="has-text-danger has-text-weight-medium">This
            action can not be undone</span>.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="deleteReport()">Delete</button>
          <button class="button" @click="deleteReportModal()">Cancel</button>
        </footer>
      </div>
    </div>

    <!-- Change Report Status Modal -->
    <div v-if="ChangeReportStatusOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-modal="true" role="dialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Change Report Status</p>
          <button class="delete" aria-label="close" @click="changeReportStatusModal()"></button>
        </header>
        <section class="modal-card-body">
          You are about to close a <strong>{{ changeReportStatusType }}</strong> incident report logged by <strong>{{
            changeReportStatusFrom }}</strong> at
          <strong>{{ changeReportStatusCamp }}</strong>. <span class="has-text-danger has-text-weight-medium">Please make
            sure the incident has been resolved before closing it</span>.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="changeRequestStatus()">Close Incident</button>
          <button class="button" @click="changeReportStatusModal()">Cancel</button>
        </footer>
      </div>
    </div>

  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { doc, collection, serverTimestamp, getDocs, query, orderBy, updateDoc, deleteDoc } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
  name: "CheckInComponent",
  setup() {
    const incidents = ref([])
    const store = useStore()
    const DeleteReportOpenClose = ref(false)
    const ChangeReportStatusOpenClose = ref(false)
    const ReportDetailsOpenClose = ref(false)

    const deleteReportId = ref(null)
    const deleteReportFrom = ref(null)
    const deleteReportType = ref(null)

    const changeReportStatustId = ref(null)
    const changeReportStatusFrom = ref(null)
    const changeReportStatusType = ref(null)
    const changeReportStatusCamp = ref(null)

    const reportDetailsId = ref(null)
    const reportDetailsFrom = ref(null)
    const reportDetailsIncident = ref(null)
    const reportDetailsCamp = ref(null)
    const reportDetailsDescription = ref(null)

    const statusFilter = ref('');
    const campFilter = ref('');


    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const getIncidents = async () => {


      const incidentsQuery = query(collection(db, "incidentreports"), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(incidentsQuery);
      // const querySnapshot = await getDocs(collection(db, "incidentreports"));
      const incidentsData = [];
      querySnapshot.forEach((doc) => {
        const createdAtTimestamp = doc.data().createdAt;
        const createdAtDate = new Date(parseInt(createdAtTimestamp, 10));
        const createdAtDateFormatted = createdAtDate.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        });

        incidentsData.push({
          id: doc.id,
          camp: doc.data().camp,
          created_at_date: createdAtDateFormatted,
          client: doc.data().client,
          incident: doc.data().incident,
          description: doc.data().description,
          status: doc.data().status,
        });
      });
      incidents.value = incidentsData;
    };

    const reportDetailsStatusModal = (id, from, incident, camp, description) => {

      if (ReportDetailsOpenClose.value == true) {

        reportDetailsId.value = null
        reportDetailsFrom.value = null
        reportDetailsIncident.value = null
        reportDetailsCamp.value = null
        reportDetailsDescription.value = null

        ReportDetailsOpenClose.value = !ReportDetailsOpenClose.value;

      } else {

        reportDetailsId.value = id;
        reportDetailsFrom.value = from;
        reportDetailsIncident.value = incident;
        reportDetailsCamp.value = camp;
        reportDetailsDescription.value = description;

        ReportDetailsOpenClose.value = !ReportDetailsOpenClose.value;
      }
    }

    const deleteReportModal = (id, from, type) => {

      if (DeleteReportOpenClose.value == true) {

        deleteReportId.value = null;
        deleteReportFrom.value = null;
        deleteReportType.value = null;

        DeleteReportOpenClose.value = !DeleteReportOpenClose.value;

      } else {

        deleteReportId.value = id;
        deleteReportFrom.value = from;
        deleteReportType.value = type;
        DeleteReportOpenClose.value = !DeleteReportOpenClose.value;
      }
    }

    const changeReportStatusModal = (id, from, type, camp) => {

      if (ChangeReportStatusOpenClose.value == true) {

        changeReportStatustId.value = null
        changeReportStatusFrom.value = null
        changeReportStatusType.value = null
        changeReportStatusCamp.value = null

        ChangeReportStatusOpenClose.value = !ChangeReportStatusOpenClose.value;

      } else {

        changeReportStatustId.value = id;
        changeReportStatusFrom.value = from;
        changeReportStatusType.value = type;
        changeReportStatusCamp.value = camp;
        ChangeReportStatusOpenClose.value = !ChangeReportStatusOpenClose.value;
      }
    }


    const changeRequestStatus = async () => {
      const newReportStatusData = {
        updated_at: serverTimestamp(),
        closed_at: serverTimestamp(),
        closed_by: auth.currentUser.uid,
        status: "closed",
      }

      try {
        const entryRef = doc(db, 'incidentreports', changeReportStatustId.value);
        await updateDoc(entryRef, newReportStatusData);
        // Do something else on success
        showToast('The incident report has been closed successfully.', 'is-success')
        getIncidents()
      } catch (error) {
        // Handle the error
        showToast('There has been an error while closing the incident report. Please try again.', 'is-danger')
      }

      changeReportStatustId.value = null
      changeReportStatusFrom.value = null
      changeReportStatusType.value = null
      changeReportStatusCamp.value = null

      ChangeReportStatusOpenClose.value = !ChangeReportStatusOpenClose.value;
    }

    const deleteReport = async () => {
      try {
        const entryRef = doc(db, 'incidentreports', deleteReportId.value);
        await deleteDoc(entryRef);
        showToast('The report has been deleted successfully.', 'is-success')
        getIncidents()
      } catch (error) {
        showToast('There has been an error while deleting the report. Please try again.', 'is-danger')
      }

      deleteReportId.value = null;
      deleteReportFrom.value = null;
      deleteReportType.value = null;

      DeleteReportOpenClose.value = !DeleteReportOpenClose.value;
    }

    const statusFilterChange = async () => {
      const status = statusFilter.value;
      // const camp = campFilter.value;

      const filteredRequestData = [];
      if (status == 'all') {
        getIncidents()
      } else {
        await getIncidents()
        incidents.value.forEach((req) => {
          if (status == req.status) {
            filteredRequestData.push(
              req
            )
          }
        })
        incidents.value = filteredRequestData
      }

    }

    const showToast = (message, type) => {
      toast({
        message: message,
        type: type,
        position: 'top-right',
        dismissible: true,
        pauseOnHover: true,
        duration: 3000, // show the message for 3 seconds
      })
    }

    const fetchData = () => {
      getIncidents();
    };

    onMounted(fetchData);
    return { user, incidents, DeleteReportOpenClose, ChangeReportStatusOpenClose, ReportDetailsOpenClose, changeReportStatustId, changeReportStatusFrom, changeReportStatusType, changeReportStatusCamp, deleteReportId, deleteReportFrom, deleteReportType, statusFilter, campFilter, reportDetailsId, reportDetailsFrom, reportDetailsIncident, reportDetailsCamp, reportDetailsDescription, statusFilterChange, deleteReportModal, changeReportStatusModal, reportDetailsStatusModal, changeRequestStatus, deleteReport, showToast }
  }
};
</script>