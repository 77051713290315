import { createStore } from 'vuex'
import { auth, db } from '../firebaseConfig'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword
} from 'firebase/auth';
import { collection, getDocs, setDoc, updateDoc, doc, serverTimestamp } from "firebase/firestore";

const store = createStore({
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user: state => state.user,
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    async register(context, { isConfirmed, name, role, email, phone, password }) {



      const response = await createUserWithEmailAndPassword(auth, email, password)
      if (response) {
        context.commit('SET_USER', response.user)

        const data = {
          isConfirmed: isConfirmed,
          name: name,
          role: role,
          phone: phone,
          status: 'active',
          uid: response.user.uid,
          user: JSON.stringify(response.user),
          created_at: serverTimestamp(),
        }

        try {
          const newMISUserRef = doc(collection(db, "mis_users"), response.user.uid);
          await setDoc(newMISUserRef, data);
          // Do something else on success
        } catch (error) {
          // Handle the error
        }
      } else {
        throw new Error('Unable to register a new MIS staff account.')
      }
    },

    async logIn(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password)
      if (response) {

        context.commit('SET_LOGGED_IN', true)
        context.commit('SET_USER', response.user)

        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('misUser', JSON.stringify(response.user));
      } else {
        throw new Error('login failed')
      }
    },
    async PassReset(context, { newPassword }) {
      try {
        const misUser = auth.currentUser;
        await updatePassword(misUser, newPassword);

        try {

          const userData = {
            uid: auth.currentUser.uid,
            user: JSON.stringify(auth.currentUser),
            isConfirmed: true,
          };
          const newMISUserRef = doc(collection(db, "mis_users"), auth.currentUser.uid);
          await updateDoc(newMISUserRef, userData);

        } catch (error) {
          throw new Error('Error updating password.')
        }

      } catch (error) {
        throw new Error('Error updating password.')
      }
    },


    async logOut(context) {
      await signOut(auth)
      context.commit('SET_LOGGED_IN', false)
      context.commit('SET_USER', null)
      localStorage.setItem('isLoggedIn', false);
      localStorage.setItem('misUser', null);
    },

    async fetchUser(context, user) {

      if (user !== null) {
        if (user.uid) {
          context.commit("SET_LOGGED_IN", true);
          context.commit("SET_USER", {
            displayName: user.displayName,
            email: user.email
          });
        } else {
          context.commit("SET_LOGGED_IN", false);
          context.commit("SET_USER", null);
        }
      } else {
        context.commit("SET_LOGGED_IN", false);

      }
    },

    async fetchData() {
      // let arr = []
      const querySnapshot = await getDocs(collection(db, "camps"));
      return querySnapshot

    },
  }
})

// export the store
export default store
