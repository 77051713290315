<template>
   <nav class="navbar is-sticky-top navigation" role="navigation" aria-label="main navigation">
      <div class="container">
         <div class="navbar-brand">
            <a class="navbar-item" href="/">
               <img class="img-fluid" :src="require(`../assets/images/newlogo.png`)" />
            </a>
         </div>
         <template v-if="isLoggedIn">
            <div id="navbar-links" class="navbar-menu">
               <div class="navbar-start ml-auto">
                  <router-link to="/" class="navbar-item">Camps</router-link>
                  <!-- Clients Dropdown -->
                  <div class="navbar-item has-dropdown is-hoverable">
                     <a class="navbar-link">Clients</a>
                     <div class="navbar-dropdown">
                        <router-link to="/clients" class="navbar-item">All Registered Users</router-link>
                        <router-link to="/feedback" class="navbar-item">Feedback</router-link>
                     </div>
                  </div>
                  <router-link to="/check-ins" class="navbar-item">Check Ins</router-link>
                  <router-link to="/requests" class="navbar-item">Service Requests</router-link>
                  <router-link to="/incidents" class="navbar-item">Incident Reports</router-link>
                  <template v-if="misUser.role == 'admin'">
                     <router-link to="/staff" class="navbar-item">Staff</router-link>
                  </template>
               </div>
               <div class="navbar-end ml-auto">
                  <button @click.prevent="signOut" style="height: 50px; margin-top: 15px" class="btn btn-primary">Sign
                     Out</button>
               </div>
            </div>

         </template>
      </div>
   </nav>
</template>
<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { toast } from 'bulma-toast'


import { ref, onMounted, onBeforeUnmount } from 'vue'
import { auth, db } from '../firebaseConfig'
import { doc, getDoc } from "firebase/firestore";


export default {

   setup() {

      const store = useStore()
      const router = useRouter()

      const misUser = ref({});
      const isLoggedIn = ref(false);

      auth.onAuthStateChanged(user => {
         store.dispatch("fetchUser", user);
      });

      const user = computed(() => {
         return store.getters.user;
      });

      const signOut = async () => {
         await store.dispatch('logOut')
         router.push('/sign-in')
         location.reload();
         showToast('You are successfully signed out.', 'is-success')
      }

      const showToast = (message, type) => {
         toast({
            message: message,
            type: type,
            position: 'top-right',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000, // show the message for 3 seconds
         })
      }

      const getMISUser = async () => {
         isLoggedIn.value = JSON.parse(localStorage.getItem('isLoggedIn'));
         const misUserLoc = JSON.parse(localStorage.getItem('misUser'));
         if (misUserLoc) {
            const misUserRef = doc(db, 'mis_users', misUserLoc.uid);

            const documentSnapshot = await getDoc(misUserRef);

            if (documentSnapshot.exists()) {
               // Document exists, access its data
               const data = documentSnapshot.data();

               misUser.value = data

            }

         }

      }

      const fetchData = () => {
         getMISUser();
      };
      onMounted(fetchData);

      // Listen for changes in localStorage and update isLoggedIn accordingly
      const handleStorageChange = (event) => {
         if (event.key === 'isLoggedIn') {
            isLoggedIn.value = JSON.parse(event.newValue);
         }
      };

      // Add event listener for storage change
      window.addEventListener('storage', handleStorageChange);

      // Cleanup function to remove event listener on component unmount
      onBeforeUnmount(() => {
         window.removeEventListener('storage', handleStorageChange);
      });

      return { user, misUser, isLoggedIn, signOut, getMISUser, showToast }
   }



};

</script>