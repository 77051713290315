<template>
    <footer>
        <div class="container">
            <div class="py-4 has-text-centered">
                <small class="text-light">MIS - Copyright © 2023 v 1.3 - Designed &amp; Developed by <a
                        href="https://openhive.co.bw">Open
                        Hive</a></small>
            </div>
        </div>
    </footer>
</template>
<script>
</script>