<template>
    <div class="add-camp">

        <!-- details page -->
        <section class="pt-5">
            <div class="container shadow section-sm rounded">
                <h5 class="mb-4 has-text-weight-small">Add Camp</h5>
                <div class="field buttons is-right">
                    <button class="button is-success" @click="AddCamp">Save Camp</button>
                </div>
                <hr>
                <div class="columns is-multiline">

                    <!-- body -->
                    <div class="column is-7-desktop is-12-tablet">

                        <div class="mb-2 px-6 py-4">
                            <div class="message is-info">
                                <h6 class="message-body">Camp information</h6>

                            </div>

                            <div class="field">
                                <label>Camp Name</label>
                                <div class="control">
                                    <input placeholder="Camp Name" type="text" class="input mb-2"
                                        v-model="add_camp.camp_name">
                                </div>
                            </div>
                            <div class="field">
                                <label>Location</label>
                                <div class="control">
                                    <input placeholder="Location" type="text" class="input mb-2"
                                        v-model="add_camp.location">
                                </div>
                            </div>
                            <div class="field">
                                <label>Contact Person (Name & Surname)</label>
                                <div class="control">
                                    <input placeholder="Contact Person (Name & Surname)" type="text" class="input mb-2"
                                        v-model="add_camp.contact_person">
                                </div>
                            </div>
                            <!-- <div class="field" v-for="(number, index) in contactNumbers" :key="index"> -->
                            <div class="field">
                                <label>Contact Number(s)</label>
                                <template v-if="contactNumbers.length">
                                    <div v-for="(number, index) in contactNumbers" :key="index">
                                        <div class="control">
                                            <div class="input-button-container">
                                                <input v-model="contactNumbers[index]" placeholder="Contact Number"
                                                    type="text" class="input mb-2">
                                                <template v-if="index != contactNumbers.length - 1">
                                                    <button class="button is-danger is-outlined"
                                                        @click="deletePhoneField(index)">
                                                        <span class="icon is-small">
                                                            <i class="ti-trash is-danger"></i>
                                                        </span>
                                                    </button>
                                                </template>
                                                <template v-if="index == contactNumbers.length - 1">
                                                    <button class="button is-info is-outlined"
                                                        @click="duplicatePhoneField(index)">
                                                        <span class="icon is-small">
                                                            <i class="ti-plus is-info"></i>
                                                        </span>
                                                    </button>
                                                </template>

                                            </div>
                                        </div>

                                    </div>

                                </template>
                                <template v-else>
                                    <div class="control">
                                        <div class="input-button-container">
                                            <input v-model="contactNumbers[0]" placeholder="Contact Number" type="text"
                                                class="input mb-2">
                                            <button class="button is-info is-outlined" @click="duplicatePhoneField(0)">
                                                <span class="icon is-small">
                                                    <i class="ti-plus is-info"></i>
                                                </span></button>

                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="field">
                                <label>Contact Email</label>
                                <div class="control">
                                    <input placeholder="Contact Email" type="email" class="input mb-2"
                                        v-model="add_camp.contact_email">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="column is-5-desktop is-12-tablet">
                        <div class="mb-2 px-2 py-4">
                            <div class="message is-info">
                                <h6 class="message-body">Camp Menu</h6>
                            </div>

                            <button class="mb-2 button is-primary is-fullwidth" @click="AddMenuItemModal(false)">Add menu
                                item</button>


                            <div v-if="Object.keys(add_camp.menu).length > 0">
                                <div v-for="(menuItems, menuWeek) in add_camp.menu.weeks" v-bind:key="menuWeek">
                                    <div class="columns mb-1 ml-3 mr-3">
                                        <div class="column is-9">
                                            <div class="list-item-content">
                                                <div
                                                    class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                                    {{ menuWeek }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <a @click.prevent="AddMenuItemModal(true, menuWeek)"
                                                class="button is-danger ml-2 is-outlined is-size-7">
                                                <span class="icon is-small">
                                                    <i class="ti-pencil is-danger"></i>
                                                </span>
                                            </a>

                                            <a @click.prevent="AddMenuItemDeleteModal(menuWeek)"
                                                class="button is-danger ml-2 is-outlined is-size-7">
                                                <span class="icon is-small">
                                                    <i class="ti-trash is-danger"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="is-size-7">
                                    No items added yet.
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- /details page -->

        <!-- Camp Menu Modal -->
        <div v-if="MenuOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Add Weekly Menu Item</p>
                    <button class="delete" aria-label="close" @click="AddMenuItemModal(false)"></button>
                </header>
                <section class="modal-card-body">
                    <!-- Are you sure you want to delete camp: <strong>{{ deleteCampName }}</strong>? <span
                        class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>. -->

                    <div class="field">
                        <label>Week</label>
                        <div class="control">
                            <input placeholder="e.g Week 1" type="text" class="input mb-2" v-model="menuWeek">
                        </div>
                    </div>
                    <article class="message is-info">
                        <div class="message-header">
                            <h6>Monday</h6>
                            <a v-if="!monday.length" class="is-info" @click="WeekDayItemModal('monday')">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="monday.length">
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        Monday menu items added.
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <a class="button is-danger ml-2 is-outlined is-size-7" @click="WeekDayItemModal('monday')">
                                    <span class="icon is-small">
                                        <i class="ti-pencil is-danger"></i>
                                    </span>
                                </a>

                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="MenuItemDeleteModal('monday')">
                                    <span class="icon is-small">
                                        <i class="ti-trash is-danger"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        No items added yet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <article class="message is-info">
                        <div class="message-header">
                            <h6>Tuesday</h6>
                            <a v-if="!tuesday.length" class="is-info" @click="WeekDayItemModal('tuesday')">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="tuesday.length">
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        Tuesday menu items added.
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <a class="button is-danger ml-2 is-outlined is-size-7" @click="WeekDayItemModal('tuesday')">
                                    <span class="icon is-small">
                                        <i class="ti-pencil is-danger"></i>
                                    </span>
                                </a>

                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="MenuItemDeleteModal('tuesday')">
                                    <span class="icon is-small">
                                        <i class="ti-trash is-danger"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        No items added yet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <article class="message is-info">
                        <div class="message-header">
                            <h6>Wednesday</h6>
                            <a v-if="!wednesday.length" class="is-info" @click="WeekDayItemModal('wednesday')">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="wednesday.length">
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        Wednesday menu items added.
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="WeekDayItemModal('wednesday')">
                                    <span class="icon is-small">
                                        <i class="ti-pencil is-danger"></i>
                                    </span>
                                </a>

                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="MenuItemDeleteModal('wednesday')">
                                    <span class="icon is-small">
                                        <i class="ti-trash is-danger"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        No items added yet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <article class="message is-info">
                        <div class="message-header">
                            <h6>Thursday</h6>
                            <a v-if="!thursday.length" class="is-info" @click="WeekDayItemModal('thursday')">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="thursday.length">
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        Thursday menu items added.
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="WeekDayItemModal('thursday')">
                                    <span class="icon is-small">
                                        <i class="ti-pencil is-danger"></i>
                                    </span>
                                </a>

                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="MenuItemDeleteModal('thursday')">
                                    <span class="icon is-small">
                                        <i class="ti-trash is-danger"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        No items added yet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <article class="message is-info">
                        <div class="message-header">
                            <h6>Friday</h6>
                            <a v-if="!friday.length" class="is-info" @click="WeekDayItemModal('friday')">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="friday.length">
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        Friday menu items added.
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <a class="button is-danger ml-2 is-outlined is-size-7" @click="WeekDayItemModal('friday')">
                                    <span class="icon is-small">
                                        <i class="ti-pencil is-danger"></i>
                                    </span>
                                </a>

                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="MenuItemDeleteModal('friday')">
                                    <span class="icon is-small">
                                        <i class="ti-trash is-danger"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        No items added yet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <article class="message is-info">
                        <div class="message-header">
                            <h6>Saturday</h6>
                            <a v-if="!saturday.length" class="is-info" @click="WeekDayItemModal('saturday')">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="saturday.length">
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        Saturday menu items added.
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="WeekDayItemModal('saturday')">
                                    <span class="icon is-small">
                                        <i class="ti-pencil is-danger"></i>
                                    </span>
                                </a>

                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="MenuItemDeleteModal('saturday')">
                                    <span class="icon is-small">
                                        <i class="ti-trash is-danger"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        No items added yet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <article class="message is-info">
                        <div class="message-header">
                            <h6>Sunday</h6>
                            <a v-if="!sunday.length" class="is-info" @click="WeekDayItemModal('sunday')">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="sunday.length">
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        Sunday menu items added.
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <a class="button is-danger ml-2 is-outlined is-size-7" @click="WeekDayItemModal('sunday')">
                                    <span class="icon is-small">
                                        <i class="ti-pencil is-danger"></i>
                                    </span>
                                </a>

                                <a class="button is-danger ml-2 is-outlined is-size-7"
                                    @click="MenuItemDeleteModal('sunday')">
                                    <span class="icon is-small">
                                        <i class="ti-trash is-danger"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="columns mb-1 ml-3 mr-3">
                            <div class="column is-9">
                                <div class="list-item-content">
                                    <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                        No items added yet.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </section>
                <footer class="modal-card-foot">
                    <div v-if="itemEdit">
                        <button class="button is-success mr-2" @click="AddMenuItem()">Update Week Item</button>
                    </div>
                    <div v-else>
                        <button class="button is-success mr-2" @click="AddMenuItem()">Add Week Item</button>
                    </div>

                    <button class="button" @click="AddMenuItemModal(false)">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Camp Menu Delete Modal -->
        <div v-if="MenuDeleteOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete Weekly Menu Item</p>
                    <button class="delete" aria-label="close" @click="AddMenuItemDeleteModal()"></button>
                </header>
                <section class="modal-card-body">
                    Are you sure you want to delete <strong>{{ menuWeek }}</strong>? <span
                        class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>.
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success mr-2" @click="AddMenuItemDelete()">Delete</button>
                    <button class="button" @click="AddMenuItemDeleteModal()">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Menu Item Modal -->
        <div v-if="MenuItemOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Menu Items</p>
                    <button class="delete" aria-label="close" @click="WeekDayItemModal()"></button>
                </header>
                <section class="modal-card-body">

                    <article class="message is-success">
                        <div class="message-header">
                            <h6>Breakfast</h6>
                            <a class="is-success" @click="BreakfastMenuItemModal()">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="breakfastMenuItems.length">
                        <div v-for="(item, index) in breakfastMenuItems" v-bind:key="index">
                            <div class="columns mb-1 ml-3 mr-3">
                                <div class="column is-9">
                                    <div class="list-item-content">
                                        <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <a class="button is-danger ml-2 is-outlined is-size-7"
                                        @click.prevent="BreakfastMenuItemModal(index)">
                                        <span class="icon is-small">
                                            <i class="ti-pencil is-danger"></i>
                                        </span>
                                    </a>

                                    <a @click.prevent="BreakfastMenuItemDeleteModal(index)"
                                        class="button is-danger ml-2 is-outlined is-size-7">
                                        <span class="icon is-small">
                                            <i class="ti-trash is-danger"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>

                    <article class="message is-success">
                        <div class="message-header">
                            <h6>Lunch</h6>
                            <a class="is-success" @click="LunchMenuItemModal()">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="lunchMenuItems.length">
                        <div v-for="(item, index) in lunchMenuItems" v-bind:key="index">
                            <div class="columns mb-1 ml-3 mr-3">
                                <div class="column is-9">
                                    <div class="list-item-content">
                                        <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <a class="button is-danger ml-2 is-outlined is-size-7"
                                        @click.prevent="LunchMenuItemModal(index)">
                                        <span class="icon is-small">
                                            <i class="ti-pencil is-danger"></i>
                                        </span>
                                    </a>

                                    <a @click.prevent="LunchMenuItemDeleteModal(index)"
                                        class="button is-danger ml-2 is-outlined is-size-7">
                                        <span class="icon is-small">
                                            <i class="ti-trash is-danger"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>

                    <article class="message is-success">
                        <div class="message-header">
                            <h6>Dinner</h6>
                            <a class="is-success" @click="DinnerMenuItemModal()">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="dinnerMenuItems.length">
                        <div v-for="(item, index) in dinnerMenuItems" v-bind:key="index">
                            <div class="columns mb-1 ml-3 mr-3">
                                <div class="column is-9">
                                    <div class="list-item-content">
                                        <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <a class="button is-danger ml-2 is-outlined is-size-7"
                                        @click.prevent="DinnerMenuItemModal(index)">
                                        <span class="icon is-small">
                                            <i class="ti-pencil is-danger"></i>
                                        </span>
                                    </a>

                                    <a @click.prevent="DinnerMenuItemDeleteModal(index)"
                                        class="button is-danger ml-2 is-outlined is-size-7">
                                        <span class="icon is-small">
                                            <i class="ti-trash is-danger"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                    <article class="message is-success">
                        <div class="message-header">
                            <h6>Live Cooking</h6>
                            <a class="is-success" @click="LiveCookingMenuItemModal()">
                                <span class="icon is-small">
                                    <i class="ti-plus"></i>
                                </span>
                            </a>
                        </div>
                    </article>

                    <template v-if="liveCookingMenuItems.length">
                        <div v-for="(item, index) in liveCookingMenuItems" v-bind:key="index">
                            <div class="columns mb-1 ml-3 mr-3">
                                <div class="column is-9">
                                    <div class="list-item-content">
                                        <div class="list-item-title is-flex is-justify-content-space-between is-size-7">
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <a class="button is-danger ml-2 is-outlined is-size-7"
                                        @click.prevent="LiveCookingMenuItemModal(index)">
                                        <span class="icon is-small">
                                            <i class="ti-pencil is-danger"></i>
                                        </span>
                                    </a>

                                    <a @click.prevent="LiveCookingMenuItemDeleteModal(index)"
                                        class="button is-danger ml-2 is-outlined is-size-7">
                                        <span class="icon is-small">
                                            <i class="ti-trash is-danger"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="MenuItem()">Add</button>
                    <button class="button" @click="WeekDayItemModal()">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Menu Item Modal -->
        <div v-if="MenuItemDeleteOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete Menu Items</p>
                    <button class="delete" aria-label="close" @click="MenuItemDeleteModal()"></button>
                </header>
                <section class="modal-card-body">
                    Are you sure you want to delete this item? <span class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="MenuItemDelete()">Delete</button>
                    <button class="button" @click="MenuItemDeleteModal()">Cancel</button>
                </footer>
            </div>
        </div>


        <!-- Breakfast Menu Item Modal -->
        <div v-if="BreakfastMenuItemOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Breakfast Menu Item</p>
                    <button class="delete" aria-label="close" @click="BreakfastMenuItemModal()"></button>
                </header>
                <section class="modal-card-body">

                    <div class="field">
                        <label>Breakfast Item</label>
                        <div class="control">
                            <input placeholder="Breakfast Item" type="text" class="input mb-2" v-model="breakfastItem">
                        </div>
                    </div>

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="BreakfastMenuItem()">Add</button>
                    <button class="button" @click="BreakfastMenuItemModal()">Cancel</button>
                </footer>
            </div>
        </div>


        <!-- Lunch Menu Item Modal -->
        <div v-if="LunchMenuItemOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Lunch Menu Item</p>
                    <button class="delete" aria-label="close" @click="LunchMenuItemModal()"></button>
                </header>
                <section class="modal-card-body">

                    <div class="field">
                        <label>Lunch Item</label>
                        <div class="control">
                            <input placeholder="Lunch Item" type="text" class="input mb-2" v-model="lunchItem">
                        </div>
                    </div>


                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="LunchMenuItem()">Add</button>
                    <button class="button" @click="LunchMenuItemModal()">Cancel</button>
                </footer>
            </div>
        </div>


        <!-- Dinner Menu Item Modal -->
        <div v-if="DinnerMenuItemOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Dinner Menu Item</p>
                    <button class="delete" aria-label="close" @click="DinnerMenuItemModal()"></button>
                </header>
                <section class="modal-card-body">

                    <div class="field">
                        <label>Dinner Item</label>
                        <div class="control">
                            <input placeholder="Dinner Item" type="text" class="input mb-2" v-model="dinnerItem">
                        </div>
                    </div>


                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="DinnerMenuItem()">Add</button>
                    <button class="button" @click="DinnerMenuItemModal()">Cancel</button>
                </footer>
            </div>
        </div>


        <!-- Live Cooking Menu Item Modal -->
        <div v-if="LiveCookingMenuItemOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Live Cooking Menu Item</p>
                    <button class="delete" aria-label="close" @click="LiveCookingMenuItemModal()"></button>
                </header>
                <section class="modal-card-body">

                    <div class="field">
                        <label>Live Cooking Item</label>
                        <div class="control">
                            <input placeholder="Live Cooking Item" type="text" class="input mb-2" v-model="liveCookingItem">
                        </div>
                    </div>


                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="LiveCookingMenuItem()">Add</button>
                    <button class="button" @click="LiveCookingMenuItemModal()">Cancel</button>
                </footer>
            </div>
        </div>


        <!-- Delete Breakfast Menu Item Modal -->
        <div v-if="BreakfastMenuItemDeleteOpenClose" class="modal is-active" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete Breakfast Menu Item</p>
                    <button class="delete" aria-label="close" @click="BreakfastMenuItemDeleteModal()"></button>
                </header>
                <section class="modal-card-body">

                    Are you sure you want to delete this breakfast item? <span
                        class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>.

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="BreakfastMenuItemDelete()">Delete</button>
                    <button class="button" @click="BreakfastMenuItemDeleteModal()">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Delete Lunch Menu Item Modal -->
        <div v-if="LunchMenuItemDeleteOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete Lunch Menu Item</p>
                    <button class="delete" aria-label="close" @click="LunchMenuItemDeleteModal()"></button>
                </header>
                <section class="modal-card-body">

                    Are you sure you want to delete this lunch item? <span
                        class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>.

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="LunchMenuItemDelete()">Delete</button>
                    <button class="button" @click="LunchMenuItemDeleteModal()">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Delete Dinner Menu Item Modal -->
        <div v-if="DinnerMenuItemDeleteOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete Dinner Menu Item</p>
                    <button class="delete" aria-label="close" @click="DinnerMenuItemDeleteModal()"></button>
                </header>
                <section class="modal-card-body">

                    Are you sure you want to delete this dinner item? <span
                        class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>.

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="DinnerMenuItemDelete()">Delete</button>
                    <button class="button" @click="DinnerMenuItemDeleteModal()">Cancel</button>
                </footer>
            </div>
        </div>

        <!-- Delete Live Cooking Menu Item Modal -->
        <div v-if="LiveCookingMenuItemDeleteOpenClose" class="modal is-active" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Delete Live Cooking Menu Item</p>
                    <button class="delete" aria-label="close" @click="LiveCookingMenuItemDeleteModal()"></button>
                </header>
                <section class="modal-card-body">

                    Are you sure you want to delete this live cooking item? <span
                        class="has-text-danger has-text-weight-medium">This
                        action can not be undone</span>.

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-danger" @click="LiveCookingMenuItemDelete()">Delete</button>
                    <button class="button" @click="LiveCookingMenuItemDeleteModal()">Cancel</button>
                </footer>
            </div>
        </div>

    </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { doc, collection, setDoc, serverTimestamp } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
    name: "DashboardComponent",
    setup() {

        const add_camp = ref({
            camp_name: '',
            location: '',
            contact_person: '',
            contact_number: '',
            contact_email: '',
            menu: {}
        });

        const contact_number = ref('')

        const camp_menu = ref({});

        const contactNumbers = ref([''])

        const breakfastItem = ref('')
        const lunchItem = ref('')
        const dinnerItem = ref('')
        const liveCookingItem = ref('')

        const breakfastMenuItems = ref([])
        const lunchMenuItems = ref([])
        const dinnerMenuItems = ref([])
        const liveCookingMenuItems = ref([])

        const itemIndex = ref(null)
        const itemEdit = ref(false)


        const monday = ref([])
        const tuesday = ref([])
        const wednesday = ref([])
        const thursday = ref([])
        const friday = ref([])
        const saturday = ref([])
        const sunday = ref([])

        const dayOfWeek = ref('')
        const menuWeek = ref('')
        const menuItems = ref([])

        const MenuOpenClose = ref(false)
        const MenuDeleteOpenClose = ref(false)
        const MenuItemOpenClose = ref(false)
        const MenuItemDeleteOpenClose = ref(false)

        const BreakfastMenuItemOpenClose = ref(false)
        const LunchMenuItemOpenClose = ref(false)
        const DinnerMenuItemOpenClose = ref(false)
        const LiveCookingMenuItemOpenClose = ref(false)

        const BreakfastMenuItemDeleteOpenClose = ref(false)
        const LunchMenuItemDeleteOpenClose = ref(false)
        const DinnerMenuItemDeleteOpenClose = ref(false)
        const LiveCookingMenuItemDeleteOpenClose = ref(false)


        const store = useStore()
        const router = useRouter()

        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
        });

        const user = computed(() => {
            return store.getters.user;
        });


        const AddCamp = async () => {
            const nonEmptyContactNumbers = contactNumbers.value.filter(number => number !== '');
            if (add_camp.value.camp_name == '') {
                showToast('The camp name is missing', 'is-danger')
                return
            }
            if (add_camp.value.location == '') {
                showToast('The camp location is missing', 'is-danger')
                return
            }
            if (add_camp.value.contact_person == '') {
                showToast('The camp contact person is missing', 'is-danger')
                return
            }
            if (nonEmptyContactNumbers.length == 0) {
                showToast('The camp contact number is missing', 'is-danger')
                return
            }
            // if (add_camp.value.contact_number == '') {
            //     showToast('The camp contact number is missing', 'is-danger')
            //     return
            // }
            if (add_camp.value.contact_email == '') {
                showToast('The camp contact email is missing', 'is-danger')
                return
            }

            if (add_camp.value.contact_email == '') {
                showToast('The camp contact email is missing', 'is-danger')
                return
            }
            const data = {
                camp_name: add_camp.value.camp_name,
                location: add_camp.value.location,
                contact_person: add_camp.value.contact_person,
                contact_number: nonEmptyContactNumbers.join(', '),
                contact_email: add_camp.value.contact_email,
                created_by: auth.currentUser.uid,
                menu: JSON.stringify(add_camp.value.menu),
                created_at: serverTimestamp(),
                updated_by: '',
                updated_at: '',
            }


            // Add a new document with a generated id
            try {
                const newCampRef = doc(collection(db, "camps"));
                await setDoc(newCampRef, data);
                // Do something else on success
                showToast('A new camp has been created successfully.', 'is-success')
                router.push({ name: 'Dashboard' })
            } catch (error) {
                // Handle the error
                showToast('There has been an error while creating a new camp. Please try again.', 'is-danger')
            }
            // await this.getCamps()

        }



        const deletePhoneField = (index) => {
            contactNumbers.value.splice(index, 1);
        }

        const duplicatePhoneField = (index) => {
            // Insert a new empty string at the next index


            // if (newContactNumber.value[0] === '') {
            //     showToast('Please add the first contact number first', 'is-danger');
            //     return;
            // }
            const newContactNumber = contactNumbers.value[index];
            if (index === 0 && newContactNumber === '') {
                showToast('Please add the first contact number first', 'is-danger');
                return;
            }

            // const newContactNumber = contactNumbers.value[index];
            if (newContactNumber !== '') {
                if (index === contactNumbers.value.length - 1) {
                    contactNumbers.value.splice(index, 1, newContactNumber, '');
                } else {
                    const nextIndex = index + 1;
                    if (contactNumbers.value[nextIndex] === '') {
                        // Replace the empty field with the new contact number
                        contactNumbers.value.splice(nextIndex, 1, newContactNumber);
                    } else {
                        // Insert the new contact number and an empty field
                        contactNumbers.value.splice(nextIndex, 0, newContactNumber, '');
                    }
                }
            }
            // contactNumbers.value.splice(index + 1, 0, contact_number.value);
            // contactNumbers.value.splice(index + 2, 0, '');
            // contactNumbers.value.splice(index + 1, 0, '');
        }

        const AddMenuItemModal = (edit, week) => {

            if (MenuOpenClose.value == true) {

                monday.value = []
                tuesday.value = []
                wednesday.value = []
                thursday.value = []
                friday.value = []
                saturday.value = []
                sunday.value = []

                menuWeek.value = ''
                itemEdit.value = false
                MenuOpenClose.value = !MenuOpenClose.value;


            } else {
                if (week) {
                    menuWeek.value = week
                    itemEdit.value = edit
                    camp_menu.value = add_camp.value.menu;

                    // Iterate through the properties of add_camp.value.menu
                    Object.keys(add_camp.value.menu.weeks[menuWeek.value]).forEach((menuKey) => {

                        switch (menuKey) {
                            case 'monday':
                                monday.value.push(add_camp.value.menu.weeks[menuWeek.value][menuKey])
                                break;
                            case 'tuesday':
                                tuesday.value.push(add_camp.value.menu.weeks[menuWeek.value][menuKey])
                                break;
                            case 'wednesday':
                                wednesday.value.push(add_camp.value.menu.weeks[menuWeek.value][menuKey])
                                break;
                            case 'thursday':
                                thursday.value.push(add_camp.value.menu.weeks[menuWeek.value][menuKey])
                                break;
                            case 'friday':
                                friday.value.push(add_camp.value.menu.weeks[menuWeek.value][menuKey])
                                break;
                            case 'saturday':
                                saturday.value.push(add_camp.value.menu.weeks[menuWeek.value][menuKey])
                                break;
                            case 'sunday':
                                sunday.value.push(add_camp.value.menu.weeks[menuWeek.value][menuKey])
                                break;
                        }
                    });


                }
                MenuOpenClose.value = !MenuOpenClose.value;
            }

        }

        const AddMenuItemDeleteModal = (week) => {

            if (MenuDeleteOpenClose.value == true) {

                menuWeek.value = ''
                MenuDeleteOpenClose.value = !MenuDeleteOpenClose.value;


            } else {
                if (week) {
                    menuWeek.value = week
                }
                MenuDeleteOpenClose.value = !MenuDeleteOpenClose.value;
            }

        }

        const WeekDayItemModal = (day) => {

            if (menuWeek.value == '') {
                showToast('Please add the week title before you continue.', 'is-danger')
                return

            } else {

                if (MenuItemOpenClose.value == true) {

                    breakfastMenuItems.value = []
                    lunchMenuItems.value = []
                    dinnerMenuItems.value = []
                    liveCookingMenuItems.value = []


                    MenuItemOpenClose.value = !MenuItemOpenClose.value;

                } else {
                    dayOfWeek.value = day;

                    const week = menuWeek.value

                    if (camp_menu.value.weeks) {
                        if (camp_menu.value.weeks[week]) {

                            if (camp_menu.value.weeks[week][day]) {

                                const menuData = camp_menu.value.weeks[week][day];

                                Object.keys(menuData).forEach(item => {
                                    switch (item) {
                                        case 'breakfast':
                                            breakfastMenuItems.value = menuData[item]
                                            break;
                                        case 'lunch':
                                            lunchMenuItems.value = menuData[item]
                                            break;
                                        case 'dinner':
                                            dinnerMenuItems.value = menuData[item]
                                            break;
                                        case 'live_cooking':
                                            liveCookingMenuItems.value = menuData[item]
                                            break;
                                    }


                                });

                            }
                        }
                    }


                    MenuItemOpenClose.value = !MenuItemOpenClose.value;
                }

            }

        }

        const MenuItemDeleteModal = (day) => {

            if (MenuItemDeleteOpenClose.value == true) {

                breakfastMenuItems.value = []
                lunchMenuItems.value = []
                dinnerMenuItems.value = []
                liveCookingMenuItems.value = []


                MenuItemDeleteOpenClose.value = !MenuItemDeleteOpenClose.value;

            } else {
                dayOfWeek.value = day;

                MenuItemDeleteOpenClose.value = !MenuItemDeleteOpenClose.value;
            }

        }


        const AddMenuItem = () => {

            if (menuWeek.value.trim() == '') {
                showToast('Please add the week title.', 'is-danger')
                return
            }

            if (!itemEdit.value) {

                if (add_camp.value.menu[menuWeek.value]) {
                    showToast(menuWeek.value + ' has been added already. Consider changing the title.', 'is-danger')
                    return

                }

            }

            if (monday.value.length === 0) {
                showToast('Please add atleast one Monday menu item.', 'is-danger')
                return
            }

            if (tuesday.value.length === 0) {
                showToast('Please add atleast one Tuesday menu item.', 'is-danger')
                return
            }

            if (wednesday.value.length === 0) {
                showToast('Please add atleast one Wednesday menu item.', 'is-danger')
                return
            }

            if (thursday.value.length === 0) {
                showToast('Please add atleast one Thursday menu item.', 'is-danger')
                return
            }

            if (friday.value.length === 0) {
                showToast('Please add atleast one Friday menu item.', 'is-danger')
                return
            }

            if (saturday.value.length === 0) {
                showToast('Please add atleast one Saturday menu item.', 'is-danger')
                return
            }

            if (sunday.value.length === 0) {
                showToast('Please add atleast one Sunday menu item.', 'is-danger')
                return
            }

            add_camp.value.menu = camp_menu.value
            camp_menu.value = {}
            monday.value = []
            tuesday.value = []
            wednesday.value = []
            thursday.value = []
            friday.value = []
            saturday.value = []
            sunday.value = []

            menuWeek.value = ''

            itemEdit.value = false;
            MenuOpenClose.value = !MenuOpenClose.value;
        }


        const AddMenuItemDelete = () => {
            delete add_camp.value.menu[menuWeek.value.trim()]

            menuWeek.value = ''

            MenuDeleteOpenClose.value = !MenuDeleteOpenClose.value;
        }

        const MenuItem = () => {

            if (breakfastMenuItems.value.length === 0) {
                showToast('Please add atleast one breakfast menu item.', 'is-danger')
                return
            }

            if (lunchMenuItems.value.length === 0) {
                showToast('Please add atleast one lunch menu item.', 'is-danger')
                return
            }

            if (dinnerMenuItems.value.length === 0) {
                showToast('Please add atleast one dinner menu item.', 'is-danger')
                return
            }

            if (liveCookingMenuItems.value.length === 0) {
                showToast('Please add atleast one live cooking menu item.', 'is-danger')
                return
            }

            // Create an object for each menu type with a key and the corresponding array of menu items
            const breakfastMenu = { key: 'breakfast', items: breakfastMenuItems.value };
            const lunchMenu = { key: 'lunch', items: lunchMenuItems.value };
            const dinnerMenu = { key: 'dinner', items: dinnerMenuItems.value };
            const liveCookingMenu = { key: 'liveCooking', items: liveCookingMenuItems.value };

            // Add the menu objects to the menuItems array
            menuItems.value.push(breakfastMenu, lunchMenu, dinnerMenu, liveCookingMenu);

            if (dayOfWeek.value == 'monday') {
                monday.value.push(menuItems)
            }

            if (dayOfWeek.value == 'tuesday') {
                tuesday.value.push(menuItems)

            }

            if (dayOfWeek.value == 'wednesday') {
                wednesday.value.push(menuItems)

            }

            if (dayOfWeek.value == 'thursday') {
                thursday.value.push(menuItems)

            }

            if (dayOfWeek.value == 'friday') {
                friday.value.push(menuItems)

            }

            if (dayOfWeek.value == 'saturday') {
                saturday.value.push(menuItems)

            }

            if (dayOfWeek.value == 'sunday') {
                sunday.value.push(menuItems)

            }

            breakfastMenuItems.value = []
            lunchMenuItems.value = []
            dinnerMenuItems.value = []
            liveCookingMenuItems.value = []

            MenuItemOpenClose.value = !MenuItemOpenClose.value;
        }

        const MenuItemDelete = () => {


            if (dayOfWeek.value == 'monday') {
                monday.value = []
                camp_menu.value.weeks[menuWeek.value][dayOfWeek.value] = []
            }

            if (dayOfWeek.value == 'tuesday') {
                tuesday.value = []
                camp_menu.value.weeks[menuWeek.value][dayOfWeek.value] = []

            }

            if (dayOfWeek.value == 'wednesday') {
                wednesday.value = []
                camp_menu.value.weeks[menuWeek.value][dayOfWeek.value] = []

            }

            if (dayOfWeek.value == 'thursday') {
                thursday.value = []
                camp_menu.value.weeks[menuWeek.value][dayOfWeek.value] = []

            }

            if (dayOfWeek.value == 'friday') {
                friday.value = []
                camp_menu.value.weeks[menuWeek.value][dayOfWeek.value] = []

            }

            if (dayOfWeek.value == 'saturday') {
                saturday.value = []
                camp_menu.value.weeks[menuWeek.value][dayOfWeek.value] = []

            }

            if (dayOfWeek.value == 'sunday') {
                sunday.value = []
                camp_menu.value.weeks[menuWeek.value][dayOfWeek.value] = []

            }

            breakfastMenuItems.value = []
            lunchMenuItems.value = []
            dinnerMenuItems.value = []
            liveCookingMenuItems.value = []

            MenuItemDeleteOpenClose.value = !MenuItemDeleteOpenClose.value;
        }


        const BreakfastMenuItemModal = (index) => {

            if (BreakfastMenuItemOpenClose.value == true) {
                itemIndex.value = null;

                BreakfastMenuItemOpenClose.value = !BreakfastMenuItemOpenClose.value;

            } else {
                if (index >= 0) {
                    breakfastItem.value = breakfastMenuItems.value[index]
                    itemIndex.value = index
                } else {
                    breakfastItem.value = ''
                }

                BreakfastMenuItemOpenClose.value = !BreakfastMenuItemOpenClose.value;
            }

        }


        const LunchMenuItemModal = (index) => {

            if (LunchMenuItemOpenClose.value == true) {
                itemIndex.value = null;

                LunchMenuItemOpenClose.value = !LunchMenuItemOpenClose.value;

            } else {
                if (index >= 0) {
                    lunchItem.value = lunchMenuItems.value[index]
                    itemIndex.value = index
                } else {
                    lunchItem.value = ''
                }
                LunchMenuItemOpenClose.value = !LunchMenuItemOpenClose.value;
            }

        }

        const DinnerMenuItemModal = (index) => {

            if (DinnerMenuItemOpenClose.value == true) {
                itemIndex.value = null;

                DinnerMenuItemOpenClose.value = !DinnerMenuItemOpenClose.value;

            } else {
                if (index >= 0) {
                    dinnerItem.value = dinnerMenuItems.value[index]
                    itemIndex.value = index
                } else {
                    dinnerItem.value = ''
                }
                DinnerMenuItemOpenClose.value = !DinnerMenuItemOpenClose.value;
            }

        }


        const LiveCookingMenuItemModal = (index) => {

            if (LiveCookingMenuItemOpenClose.value == true) {
                itemIndex.value = null;

                LiveCookingMenuItemOpenClose.value = !LiveCookingMenuItemOpenClose.value;

            } else {

                if (index >= 0) {
                    liveCookingItem.value = liveCookingMenuItems.value[index]
                    itemIndex.value = index
                } else {
                    liveCookingItem.value = ''
                }
                LiveCookingMenuItemOpenClose.value = !LiveCookingMenuItemOpenClose.value;
            }

        }

        const BreakfastMenuItem = () => {
            if (breakfastItem.value == '') {
                showToast('Please specify the breakfast item.', 'is-danger')
                return
            }
            const weekName = menuWeek.value;
            const weekDay = dayOfWeek.value;
            const itemType = "breakfast";

            if (itemIndex.value !== null && itemIndex.value >= 0) {
                breakfastMenuItems.value[itemIndex.value] = breakfastItem.value
                camp_menu.value.weeks[weekName][weekDay][itemType] = breakfastMenuItems.value;
                itemIndex.value = null
            } else {
                breakfastMenuItems.value.push(breakfastItem.value)

                // Check if the main structure exists
                if (!camp_menu.value.weeks) {
                    camp_menu.value.weeks = {};
                }

                // Check if the week structure exists
                if (!camp_menu.value.weeks[weekName]) {
                    camp_menu.value.weeks[weekName] = {};
                }

                // Check if the day structure exists
                if (!camp_menu.value.weeks[weekName][weekDay]) {
                    camp_menu.value.weeks[weekName][weekDay] = {};
                }

                // Check if the itemType structure exists
                if (!camp_menu.value.weeks[weekName][weekDay][itemType]) {
                    camp_menu.value.weeks[weekName][weekDay][itemType] = [];
                }

                camp_menu.value.weeks[weekName][weekDay][itemType] = breakfastMenuItems.value;
            }

            breakfastItem.value = ''
            BreakfastMenuItemOpenClose.value = !BreakfastMenuItemOpenClose.value;
        }


        const LunchMenuItem = () => {
            if (lunchItem.value == '') {
                showToast('Please specify the lunch item.', 'is-danger')
                return
            }
            const weekName = menuWeek.value;
            const weekDay = dayOfWeek.value;
            const itemType = "lunch";

            if (itemIndex.value !== null && itemIndex.value >= 0) {
                lunchMenuItems.value[itemIndex.value] = lunchItem.value
                camp_menu.value.weeks[weekName][weekDay][itemType] = lunchMenuItems.value;
                itemIndex.value = null
            } else {
                lunchMenuItems.value.push(lunchItem.value)

                // Check if the main structure exists
                if (!camp_menu.value.weeks) {
                    camp_menu.value.weeks = {};
                }

                // Check if the week structure exists
                if (!camp_menu.value.weeks[weekName]) {
                    camp_menu.value.weeks[weekName] = {};
                }

                // Check if the day structure exists
                if (!camp_menu.value.weeks[weekName][weekDay]) {
                    camp_menu.value.weeks[weekName][weekDay] = {};
                }

                // Check if the itemType structure exists
                if (!camp_menu.value.weeks[weekName][weekDay][itemType]) {
                    camp_menu.value.weeks[weekName][weekDay][itemType] = [];
                }

                camp_menu.value.weeks[weekName][weekDay][itemType] = lunchMenuItems.value;
            }

            lunchItem.value = ''
            LunchMenuItemOpenClose.value = !LunchMenuItemOpenClose.value;
        }


        const DinnerMenuItem = () => {

            if (dinnerItem.value == '') {
                showToast('Please specify the dinner item.', 'is-danger')
                return
            }
            const weekName = menuWeek.value;
            const weekDay = dayOfWeek.value;
            const itemType = "dinner";

            if (itemIndex.value !== null && itemIndex.value >= 0) {
                dinnerMenuItems.value[itemIndex.value] = dinnerItem.value
                camp_menu.value.weeks[weekName][weekDay][itemType] = dinnerMenuItems.value;
                itemIndex.value = null
            } else {
                dinnerMenuItems.value.push(dinnerItem.value)

                // Check if the main structure exists
                if (!camp_menu.value.weeks) {
                    camp_menu.value.weeks = {};
                }

                // Check if the week structure exists
                if (!camp_menu.value.weeks[weekName]) {
                    camp_menu.value.weeks[weekName] = {};
                }

                // Check if the day structure exists
                if (!camp_menu.value.weeks[weekName][weekDay]) {
                    camp_menu.value.weeks[weekName][weekDay] = {};
                }

                // Check if the itemType structure exists
                if (!camp_menu.value.weeks[weekName][weekDay][itemType]) {
                    camp_menu.value.weeks[weekName][weekDay][itemType] = [];
                }

                camp_menu.value.weeks[weekName][weekDay][itemType] = dinnerMenuItems.value;
            }

            dinnerItem.value = ''
            DinnerMenuItemOpenClose.value = !DinnerMenuItemOpenClose.value;

        }


        const LiveCookingMenuItem = () => {

            if (liveCookingItem.value == '') {
                showToast('Please specify the live cooking item.', 'is-danger')
                return
            }
            const weekName = menuWeek.value;
            const weekDay = dayOfWeek.value;
            const itemType = "live_cooking";

            if (itemIndex.value !== null && itemIndex.value >= 0) {
                liveCookingMenuItems.value[itemIndex.value] = liveCookingItem.value
                camp_menu.value.weeks[weekName][weekDay][itemType] = liveCookingMenuItems.value;
                itemIndex.value = null
            } else {
                liveCookingMenuItems.value.push(liveCookingItem.value)

                // Check if the main structure exists
                if (!camp_menu.value.weeks) {
                    camp_menu.value.weeks = {};
                }

                // Check if the week structure exists
                if (!camp_menu.value.weeks[weekName]) {
                    camp_menu.value.weeks[weekName] = {};
                }

                // Check if the day structure exists
                if (!camp_menu.value.weeks[weekName][weekDay]) {
                    camp_menu.value.weeks[weekName][weekDay] = {};
                }

                // Check if the itemType structure exists
                if (!camp_menu.value.weeks[weekName][weekDay][itemType]) {
                    camp_menu.value.weeks[weekName][weekDay][itemType] = [];
                }

                camp_menu.value.weeks[weekName][weekDay][itemType] = liveCookingMenuItems.value;
            }

            liveCookingItem.value = ''
            LiveCookingMenuItemOpenClose.value = !LiveCookingMenuItemOpenClose.value;

        }

        const BreakfastMenuItemDeleteModal = (index) => {

            if (BreakfastMenuItemDeleteOpenClose.value == true) {
                itemIndex.value = null;

                BreakfastMenuItemDeleteOpenClose.value = !BreakfastMenuItemDeleteOpenClose.value;

            } else {
                if (index >= 0) {
                    itemIndex.value = index

                    BreakfastMenuItemDeleteOpenClose.value = !BreakfastMenuItemDeleteOpenClose.value;
                }
            }

        }

        const LunchMenuItemDeleteModal = (index) => {

            if (LunchMenuItemDeleteOpenClose.value == true) {
                itemIndex.value = null;

                LunchMenuItemDeleteOpenClose.value = !LunchMenuItemDeleteOpenClose.value;

            } else {
                if (index >= 0) {
                    itemIndex.value = index

                    LunchMenuItemDeleteOpenClose.value = !LunchMenuItemDeleteOpenClose.value;
                }
            }

        }

        const DinnerMenuItemDeleteModal = (index) => {

            if (DinnerMenuItemDeleteOpenClose.value == true) {
                itemIndex.value = null;

                DinnerMenuItemDeleteOpenClose.value = !DinnerMenuItemDeleteOpenClose.value;

            } else {
                if (index >= 0) {
                    itemIndex.value = index

                    DinnerMenuItemDeleteOpenClose.value = !DinnerMenuItemDeleteOpenClose.value;
                }
            }

        }

        const LiveCookingMenuItemDeleteModal = (index) => {

            if (LiveCookingMenuItemDeleteOpenClose.value == true) {
                itemIndex.value = null;

                LiveCookingMenuItemDeleteOpenClose.value = !LiveCookingMenuItemDeleteOpenClose.value;

            } else {
                if (index >= 0) {
                    itemIndex.value = index

                    LiveCookingMenuItemDeleteOpenClose.value = !LiveCookingMenuItemDeleteOpenClose.value;
                }
            }

        }

        const BreakfastMenuItemDelete = () => {
            if (itemIndex.value !== null && itemIndex.value >= 0) {
                breakfastMenuItems.value.splice(itemIndex.value, 1)
                itemIndex.value = null
            }

            BreakfastMenuItemDeleteOpenClose.value = !BreakfastMenuItemDeleteOpenClose.value;
        }

        const LunchMenuItemDelete = () => {

            if (itemIndex.value !== null && itemIndex.value >= 0) {
                lunchMenuItems.value.splice(itemIndex.value, 1)
                itemIndex.value = null
            }

            LunchMenuItemDeleteOpenClose.value = !LunchMenuItemDeleteOpenClose.value;
        }

        const DinnerMenuItemDelete = () => {

            if (itemIndex.value !== null && itemIndex.value >= 0) {
                dinnerMenuItems.value.splice(itemIndex.value, 1)
                itemIndex.value = null
            }

            DinnerMenuItemDeleteOpenClose.value = !DinnerMenuItemDeleteOpenClose.value;
        }

        const LiveCookingMenuItemDelete = () => {

            if (itemIndex.value !== null && itemIndex.value >= 0) {
                liveCookingMenuItems.value.splice(itemIndex.value, 1)
                itemIndex.value = null
            }

            LiveCookingMenuItemDeleteOpenClose.value = !LiveCookingMenuItemDeleteOpenClose.value;
        }

        const showToast = (message, type) => {
            toast({
                message: message,
                type: type,
                position: 'top-right',
                dismissible: true,
                pauseOnHover: true,
                duration: 3000, // show the message for 3 seconds
            })
        }
        return { user, contact_number, add_camp, camp_menu, contactNumbers, MenuOpenClose, MenuDeleteOpenClose, MenuItemOpenClose, MenuItemDeleteOpenClose, breakfastItem, lunchItem, dinnerItem, itemIndex, itemEdit, liveCookingItem, BreakfastMenuItemOpenClose, LunchMenuItemOpenClose, DinnerMenuItemOpenClose, LiveCookingMenuItemOpenClose, BreakfastMenuItemDeleteOpenClose, LunchMenuItemDeleteOpenClose, DinnerMenuItemDeleteOpenClose, LiveCookingMenuItemDeleteOpenClose, breakfastMenuItems, lunchMenuItems, dinnerMenuItems, liveCookingMenuItems, menuWeek, monday, tuesday, wednesday, thursday, friday, saturday, sunday, duplicatePhoneField, deletePhoneField, BreakfastMenuItem, LunchMenuItem, DinnerMenuItem, LiveCookingMenuItem, WeekDayItemModal, MenuItemDeleteModal, AddMenuItemModal, AddMenuItemDeleteModal, BreakfastMenuItemModal, LunchMenuItemModal, DinnerMenuItemModal, LiveCookingMenuItemModal, BreakfastMenuItemDeleteModal, LunchMenuItemDeleteModal, DinnerMenuItemDeleteModal, LiveCookingMenuItemDeleteModal, BreakfastMenuItemDelete, LunchMenuItemDelete, DinnerMenuItemDelete, LiveCookingMenuItemDelete, MenuItem, MenuItemDelete, AddMenuItem, AddMenuItemDelete, AddCamp, showToast }
    }
};

</script>
<style scoped>
.modal-card {
    top: 10%;
    max-height: 75vh;
    overflow-y: auto;
}

.input-button-container {
    display: flex;
    align-items: flex-start;
    /* Align items at the top */
}

.input-button-container input,
.input-button-container button {
    align-self: flex-start;
    /* Align individual items at the top */
    margin-right: 5px;
    /* Adjust as needed */
}

.input-button-container button {
    flex-shrink: 0;
}

.input-button-container button {
    margin-top: 0px;
    /* Adjust as needed */
}
</style>