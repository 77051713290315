<template>
    <div class="staff">

        <!-- details page -->
        <section class="pt-5">
            <div class="container shadow section-sm rounded">
                <div class="columns is-multiline">

                    <!-- body -->
                    <div class="column is-11-desktop is-12-tablet">
                        <!-- <div class="px-4"> -->
                        <h2 class="mb-4 has-text-weight-medium">Staff Accounts</h2> <!-- main content -->
                        <button @click="addMISUserModal()" class="btn btn-sm btn-primary mb-4">Add New Staff
                            Account</button>
                        <template v-if="misUsers.length">
                            <table class="table is-striped is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="(item, index) in misUsers" v-bind:key="item.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.name }}</td>

                                        <template v-if="item.role == 'staff'">
                                            <td><span style="color: green">Staff</span></td>
                                        </template>
                                        <template v-if="item.role == 'admin'">
                                            <td><span style="color: orange">Administrator</span></td>
                                        </template>
                                        <td>{{ item.email }}</td>
                                        <td>{{ item.phone }}</td>
                                        <template v-if="item.status == 'active'">
                                            <td><span style="color: green">Active</span></td>
                                        </template>
                                        <template v-if="item.status == 'deactivated'">
                                            <td><span style="color: orange">Deactivated</span></td>
                                        </template>

                                    </tr>

                                </tbody>
                            </table>

                        </template>
                        <template v-else>
                            <hr class="ml-4">
                            <p class="ml-4"> There are no users available yet</p>
                        </template>

                    </div>
                </div>
            </div>
        </section>
        <!-- /details page -->

        <!-- Activate User Modal -->
        <div v-if="AddStaffOpenClose" class="modal is-active" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-modal="true" role="dialog">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Add New Staff Account</p>
                    <button class="delete" aria-label="close" @click="addMISUserModal()"></button>
                </header>
                <section class="modal-card-body">
                    <div class="mb-2 px-6 py-4">
                        <div class="message is-info">
                            <h6 class="message-body">User information</h6>

                        </div>

                        <div class="field">
                            <label>Full Names</label>
                            <div class="control">
                                <input v-model="add_mis_user.name" placeholder="Full Names" type="text" class="input mb-2">
                            </div>
                        </div>
                        <div class="field">
                            <label>Email</label>
                            <div class="control">
                                <input v-model="add_mis_user.email" placeholder="Email" type="email" class="input mb-2">
                            </div>
                        </div>
                        <div class="field">
                            <label>Phone Number</label>
                            <div class="control">
                                <input v-model="add_mis_user.phone" placeholder="Phone Number" type="text"
                                    class="input mb-2">
                            </div>
                        </div>
                        <div class="field">
                            <label>Password</label>
                            <div class="control">
                                <input v-model="add_mis_user.password" placeholder="Password" type="password"
                                    class="input mb-2">
                            </div>
                        </div>
                        <div class="field">
                            <label>Confirm Password</label>
                            <div class="control">
                                <input v-model="add_mis_user.password_con" placeholder="Confirm Password" type="password"
                                    class="input mb-2">
                            </div>
                        </div>
                        <div class="select">
                            <select v-model="add_mis_user.role">
                                <option value="" disabled selected>Select Role</option>
                                <option value="admin">Administrator</option>
                                <option value="staff">Normal Staff</option>
                            </select>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="addMISUser()">Create</button>
                    <button class="button" @click="addMISUserModal()">Cancel</button>
                </footer>
            </div>
        </div>

    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { computed } from "vue";
import { auth, db } from '../firebaseConfig'
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { toast } from 'bulma-toast'

export default {
    name: "ClientsComponent",
    setup() {
        const misUsers = ref([]);

        const statusFilter = ref('');
        const userId = ref(null);
        const userName = ref(null);
        const AddStaffOpenClose = ref(false)

        const store = useStore()

        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
        });

        const user = computed(() => {
            return store.getters.user;
        });

        const add_mis_user = ref({
            isConfirmed: false,
            name: '',
            role: '',
            email: '',
            phone: '',
            password: '',
            password_con: ''
        });

        const getMISUsers = async () => {
            const misUsersQuery = query(collection(db, "mis_users"), orderBy('created_at', 'desc'));
            const querySnapshot = await getDocs(misUsersQuery);
            // const querySnapshot = await getDocs(collection(db, "mis_users"));
            const misUserData = [];
            querySnapshot.forEach((doc) => {

                misUserData.push({
                    uid: doc.data().uid,
                    name: doc.data().name,
                    role: doc.data().role,
                    email: JSON.parse(doc.data().user).email,
                    phone: doc.data().phone,
                    status: doc.data().status,
                });
            });
            misUsers.value = misUserData;
        };

        const addMISUserModal = () => {
            AddStaffOpenClose.value = !AddStaffOpenClose.value;
        }

        const addMISUser = async () => {

            if (add_mis_user.value.name == '') {
                showToast('Please enter the staff Full Name', 'is-danger')
                return
            }

            if (add_mis_user.value.email == '') {
                showToast('Please enter the staff Email', 'is-danger')
                return
            }

            if (add_mis_user.value.phone == '') {
                showToast('Please enter the staff Phone Number', 'is-danger')
                return
            }

            if (add_mis_user.value.password == '') {
                showToast('Please enter the Password', 'is-danger')
                return
            }
            if (add_mis_user.value.password_con == '') {
                showToast('Please enter the Password Confirmation', 'is-danger')
                return
            }
            if (add_mis_user.value.password != add_mis_user.value.password_con) {
                showToast('The password and password confirmation do not match', 'is-danger')
                return

            }
            if (add_mis_user.value.role == '') {
                showToast('Please select the staff role', 'is-danger')
                return
            }
            try {
                await store.dispatch('register', {
                    isConfirmed: add_mis_user.value.isConfirmed,
                    name: add_mis_user.value.name,
                    role: add_mis_user.value.role,
                    email: add_mis_user.value.email,
                    phone: add_mis_user.value.phone,
                    password: add_mis_user.value.password,
                })

                showToast('A new MIS staff account has been created successfully.', 'is-success')
                await getMISUsers()
                addMISUserModal()
            }
            catch (err) {
                showToast("MIS Staff account creation failed. Please try again", "is-danger")
            }
        }



        const showToast = (message, type) => {
            toast({
                message: message,
                type: type,
                position: 'top-right',
                dismissible: true,
                pauseOnHover: true,
                duration: 3000, // show the message for 3 seconds
            })
        }

        const statusFilterChange = async () => {
            const status = statusFilter.value;
            // const camp = campFilter.value;

            const filteredRequestData = [];
            if (status == 'all') {
                getMISUsers()
            } else {
                await getMISUsers()
                misUsers.value.forEach((req) => {
                    if (status == req.status) {
                        filteredRequestData.push(
                            req
                        )
                    }
                })
                misUsers.value = filteredRequestData
            }

        }

        const fetchData = async () => {
            await getMISUsers();
        };

        onMounted(fetchData);
        return { user, add_mis_user, userId, userName, misUsers, AddStaffOpenClose, statusFilter, addMISUser, addMISUserModal, showToast, statusFilterChange }
    }
};
</script>
<style scoped>
.modal-card {
    top: 10%;
    max-height: 75vh;
    overflow-y: auto;
}
</style>