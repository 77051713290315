<template>
    <section class="pt-5">
        <div class="container shadow section-sm rounded">
            <div class="columns is-multiline">

                <!-- body -->
                <div class="column is-11-desktop is-12-tablet">
                    <article class="message is-warning">
                        <!-- <div class="message-header">
                            <p>Primary</p>
                        </div> -->
                        <div class="message-body">
                            <strong>Please reset your password to continue.</strong>
                        </div>
                    </article>
                    <div class="column is-4 is-offset-4">
                        <h2 class="mb-4 has-text-weight-medium ">Reset Password</h2> <!-- main content -->
                        <form action="#" @submit.prevent="PassReset">
                            <div class="notification is-danger" v-if="error">
                                <p>
                                    <span class="has-text-white">{{ error }}</span>
                                </p>
                            </div>


                            <div class="field">
                                <label>Password</label>
                                <div class="control">
                                    <input type="password" class="input" v-model="password">
                                </div>
                            </div>

                            <div class="field">
                                <label>Confirm Password</label>
                                <div class="control">
                                    <input type="password" class="input" v-model="confirm_password">
                                </div>
                            </div>


                            <div class="field">
                                <div class="control">
                                    <button type="submit" class="btn btn-primary">Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { toast } from 'bulma-toast'
import { useRouter } from 'vue-router'

export default {
    name: "LoginComponent",
    setup() {
        const password = ref('')
        const confirm_password = ref('')
        const error = ref(null)

        const store = useStore()
        const router = useRouter()

        const PassReset = async () => {
            if (password.value.length < 6) {
                error.value = 'Please enter a password with six or more characters. Please try again.'
                return
            }
            if (password.value == '') {
                error.value = 'Please enter the password. Please try again.'
                return
            }

            if (confirm_password.value == '') {
                error.value = 'Please enter the password confirmation. Please try again.'
                return
            }

            if (confirm_password.value != password.value) {
                error.value = 'The passwords do not match. Please try again.'
                return
            }
            try {
                await store.dispatch('PassReset', {
                    newPassword: password.value
                })

                showToast('Your password has been saved successfully.', 'is-success')
                router.push('/')
            }
            catch (err) {
                error.value = err.message
            }
        }

        const showToast = (message, type) => {
            toast({
                message: message,
                type: type,
                position: 'top-right',
                dismissible: true,
                pauseOnHover: true,
                duration: 3000, // show the message for 3 seconds
            })
        }
        return { PassReset, showToast, confirm_password, password, error }
    }
};
</script>