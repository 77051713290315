<template>
    <div class="not-found">

        <!-- details page -->
        <section class="pt-5">

            <div class="is-flex is-justify-content-center is-align-items-center" style=" height: 50vh;">
                <div class="has-text-centered">
                    <h1 class="is-size-1 has-text-weight-bold" style="color: #8CAE50">404</h1>
                    <p class="is-size-5 has-text-weight-medium"> <span class="has-text-danger">Opps!</span> Page not found.
                    </p>
                    <p class="is-size-6 mb-2">
                        The page you’re looking for doesn’t exist.
                    </p>
                    <router-link to="/" class="btn btn-primary">Go Home</router-link>
                </div>
            </div>
        </section>
        <!-- /details page -->
    </div>
</template>