import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue';
import PassReset from '../components/PassReset.vue';
import Dashboard from '../components/Dashboard.vue';
import RequestsVue from '../components/RequestsVue.vue';
import IncidentsVue from '../components/IncidentsVue.vue';
import NotFound from '../components/NotFound.vue';
import ClientsVue from '../components/ClientsVue.vue';
import StaffVue from '../components/StaffVue.vue';
import ClientVue from '../views/ClientVue.vue';
import FeedbackVue from '../views/FeedbackVue.vue';
import AddCampVue from '../views/AddCampVue.vue';
import UpdateCampVue from '../views/UpdateCampVue.vue';
import CampVue from '../views/CampVue.vue';
import CheckInsVue from '../views/CheckInsVue.vue';
import GuestQuestionnairesVue from '../views/GuestQuestionnairesVue.vue';
import { db } from '../firebaseConfig'
import { doc, getDoc } from "firebase/firestore";
import { toast } from 'bulma-toast'


const routes = [
    {
        path: '/sign-in',
        name: 'Login',
        component: Login,
        meta: { requiresAuth: false, role: 'staff' }
    },
    {
        path: '/password-reset',
        name: 'PassReset',
        component: PassReset,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/requests',
        name: 'Requests',
        component: RequestsVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/incidents',
        name: 'Incidents',
        component: IncidentsVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: FeedbackVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/clients',
        name: 'Clients',
        component: ClientsVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/staff',
        name: 'Staff',
        component: StaffVue,
        meta: { requiresAuth: true, role: 'admin' }
    },
    {
        path: '/client',
        name: 'Client',
        component: ClientVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/camp',
        name: 'Camp',
        component: CampVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/check-ins',
        name: 'CheckInsVue',
        component: CheckInsVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/guest-questionnaires',
        name: 'GuestQuestionnairesVue',
        component: GuestQuestionnairesVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/dashboard/add-camp',
        name: 'AddCampVue',
        component: AddCampVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/dashboard/update-camp/:slug',
        name: 'UpdateCampVue',
        component: UpdateCampVue,
        meta: { requiresAuth: true, role: 'staff' }
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
        meta: { requiresAuth: true, role: 'staff' }
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes
})

const showToast = (message, type) => {
    toast({
        message: message,
        type: type,
        position: 'top-right',
        dismissible: true,
        pauseOnHover: true,
        duration: 3000, // show the message for 3 seconds
    })
}

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const role = to.meta.role;
    const isExistingRoute = router.hasRoute(to.name);
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    const misUser = JSON.parse(localStorage.getItem('misUser'));

    if (misUser) {

        const misUserRef = doc(db, 'mis_users', misUser.uid);
        var isConfirmed = false;
        var misRole = ''

        // Use 'getDoc' to retrieve the document
        const documentSnapshot = await getDoc(misUserRef);

        if (documentSnapshot.exists()) {
            // Document exists, access its data
            const data = documentSnapshot.data();

            isConfirmed = data.isConfirmed;
            misRole = data.role

        }


        if (to.name != 'PassReset' && requiresAuth && isLoggedIn && !isConfirmed) {
            next({ name: 'PassReset' });
        }
    }

    if (requiresAuth && !isLoggedIn) {
        // If the route requires authentication and the user is not signed in
        next({ name: 'Login' });
    } else if (!requiresAuth && isLoggedIn) {
        // If the route requires authentication and the user is not signed in
        next({ name: 'Dashboard' });
    }
    else {
        // Otherwise, proceed to the next route
        if (isExistingRoute) {
            if (typeof misRole !== 'undefined' && misRole !== '') {
                if (misRole === 'admin' || misRole === role) {
                    next();
                } else {
                    showToast('Access restricted', 'is-danger')
                    next({ name: 'Dashboard' });
                }

            } else {
                next();
            }
            // If the requested route exists, proceed to the next route
            // if (misRole != '') {
            //     if (misRole === 'admin' || misRole === role) {

            //     } else {
            //         next({ name: 'Dashboard' });
            //     }

            // } else {
            //     next();
            // }
        } else {
            // Otherwise, redirect to the 404 page
            next({ name: 'NotFound' });
        }
    }
});
export default router
