<template>
  <section class="pt-5">
    <div class="container shadow section-sm rounded">
      <div class="columns is-multiline">

        <!-- body -->
        <div class="column is-11-desktop is-12-tablet">
          <div class="column is-4 is-offset-4">
            <h2 class="mb-4 has-text-weight-medium ">Sign In</h2> <!-- main content -->
            <form action="#" @submit.prevent="Login">
              <div class="notification is-danger" v-if="error">
                <p>
                  <span class="has-text-white">{{ error }}</span>
                </p>
              </div>
              <div class="field">
                <label>Email</label>
                <div class="control">
                  <input type="email" class="input" required autofocus v-model="email">
                </div>
              </div>

              <div class="field">
                <label>Password</label>
                <div class="control">
                  <input type="password" class="input" required v-model="password">
                </div>
              </div>


              <div class="field">
                <div class="control">
                  <button type="submit" class="btn btn-primary">Sign In</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { toast } from 'bulma-toast'

export default {
  name: "LoginComponent",
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref(null)

    const store = useStore()
    const router = useRouter()

    const Login = async () => {
      try {
        await store.dispatch('logIn', {
          email: email.value,
          password: password.value
        })
        router.push('/')
        location.reload();
        showToast('You are successfully signed in.', 'is-success')
      }
      catch (err) {
        error.value = err.message
      }
    }

    const showToast = (message, type) => {
      toast({
        message: message,
        type: type,
        position: 'top-right',
        dismissible: true,
        pauseOnHover: true,
        duration: 3000, // show the message for 3 seconds
      })
    }
    return { Login, showToast, email, password, error }
  }
};
</script>